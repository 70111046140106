import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { changeStatus } from "api/orders/orders";

interface StatusPayload {
  orderId: string;
  status: string;
}

export const useChangeOrderStatus = () => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation((order: StatusPayload) => changeStatus(order), {
    onSuccess: (_, variables, context: { refetch: () => void }) => {
      toast({
        title: "Order Status was changed successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      context?.refetch();
      queryClient.invalidateQueries(["ownerOrders"]);
    },
    onError: () => {
      toast({
        title: "Order status failed to be changed!",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    },
  });
};
