import React, { useEffect, useState } from "react";
import Card from "components/card";
import { IoMdRefresh } from "react-icons/io";
import { Table, Select } from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import {
  IoChevronBackCircleSharp,
  IoChevronForwardCircleSharp,
} from "react-icons/io5";
import { ShopResponse } from "interfaces/shop/shop";
import { FaSort, FaUndo } from "react-icons/fa";
import { OrderReport, OrderReportResult } from "api/reports/reports";
import { SortType } from "interfaces/product/product";

function ReportTable(props: {
  tableData: OrderReportResult;
  itemsPerPage: number;
  currentPage: number;
  handlePageChange: (pageNumber: number) => void;
  flowerShops: ShopResponse[];
  selectedId: string | null;
  handleShopChange: (id: string | null) => void;
  sortField: string;
  sortType: SortType;
  handleSortChange: (field: string, type: SortType) => void;
}) {
  const {
    tableData,
    currentPage,
    handlePageChange,
    flowerShops,
    selectedId,
    handleShopChange,
    sortField,
    sortType,
    handleSortChange,
  } = props;
  const totalPages = tableData.totalPages;
  const [searchTerm, setSearchTerm] = useState("");
  const [displayedData, setDisplayedData] = useState<OrderReport[]>([]);
  const [sortConfig, setSortConfig] = useState<{
    field: string;
    direction: "asc" | "desc" | "default";
  }>({
    field: "",
    direction: "default",
  });

  const sortData = (
    data: OrderReport[],
    {
      field,
      direction,
    }: { field: string; direction: "asc" | "desc" | "default" }
  ) => {
    if (direction === "default") return data;

    return data.sort((a, b) => {
      const aValue = a.placeDetails?.name?.toLowerCase();
      const bValue = b.placeDetails?.name?.toLowerCase();

      if (aValue < bValue) {
        return direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  // useEffect(() => {
  //   setDisplayedData(tableData.results);
  // }, [tableData, currentPage, sortField, sortType]);

  // useEffect(() => {
  //   const filteredData = tableData.results.filter((order) =>
  //     order.placeDetails.name?.toLowerCase().includes(searchTerm.toLowerCase())
  //   );

  //   const sortedData =
  //     sortConfig.direction === "default"
  //       ? filteredData
  //       : sortData(filteredData, sortConfig);

  //   setDisplayedData(sortedData);
  // }, [tableData, searchTerm, sortConfig]);
  // useEffect(() => {
  //   setDisplayedData(tableData.results);
  // }, [tableData, currentPage, sortField, sortType]);

  useEffect(() => {
    const filteredData = tableData.results.filter((order) =>
      order.placeDetails.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sortedData =
      sortConfig.direction === "default"
        ? filteredData
        : sortData(filteredData, sortConfig);

    setDisplayedData(sortedData);
  }, [tableData, searchTerm, sortConfig]);

  const handleSortToggle = (columnName: string) => {
    const nextSortType =
      sortType === SortType.ASC ? SortType.DESC : SortType.ASC;
    handleSortChange(columnName, nextSortType);
  };

  const columnNames = [
    "Flowershop Name",
    "Day",
    "All Sum (exc. GST)",
    "To be Recieved (exc. GST)",
    "Status",
  ];

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const formatDate = (isoDate: string) => {
    if (!isoDate) return "N/A";
    const date = new Date(isoDate);
    return new Intl.DateTimeFormat("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }).format(date);
  };

  return (
    <>
      <div className="mb-5 flex h-[40px] items-center rounded-full border-[1px] border-gray-200 bg-[white] text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[250px]">
        <p className="pl-3 pr-2 text-xl">
          <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
        </p>
        <input
          type="text"
          placeholder="Search by Flower Shop name..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="block h-full w-full rounded-full bg-[white] text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit xl:w-[200px]"
        />
      </div>
      <Card extra={"w-full h-full sm:overflow-auto px-6"}>
        <header className="relative mb-4 flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Owner Reports
          </div>
          <div className="flex items-center gap-4">
            <Select
              value={selectedId || ""}
              onChange={(e) =>
                handleShopChange(e.target.value === "" ? null : e.target.value)
              }
              sx={{
                borderRadius: "0.75rem",
                height: "3.5em",
                fontSize: "14px",
                width: 60,
              }}
              className="border bg-white/0 outline-none"
              variant="auth"
              placeholder="Select Flower Shop"
            >
              <option value="">All Shops</option>{" "}
              {Array.isArray(flowerShops) &&
                flowerShops.map((shop) => (
                  <option key={shop._id} value={shop._id}>
                    {shop.name}
                  </option>
                ))}
            </Select>
            {selectedId && (
              <FaUndo
                className="cursor-pointer"
                onClick={() => handleShopChange(null)}
              />
            )}
          </div>
        </header>
        <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
          {displayedData?.length > 0 ? (
            <Table className="w-full">
              <thead>
                <tr className="bg-gray-200">
                  {columnNames?.map((columnName, index) => (
                    <th
                      key={index}
                      className={`cursor-pointer border-b-[1px] border-gray-200 pb-2 pl-2 pt-4 text-start ${
                        index === 0 ? "rounded-tl-md" : ""
                      } ${
                        index === columnNames.length - 1 ? "rounded-tr-md" : ""
                      }`}
                    >
                      <div className="flex items-center justify-between text-sm text-[#a3aed0]">
                        {columnName}
                        {/* {index === 0 && (
                          <FaSort
                            onClick={() =>
                              handleSortToggle("placeDetails.name")
                            }
                            className={`ml-2 cursor-pointer ${
                              sortConfig.field === "placeDetails.name"
                                ? sortConfig.direction === "asc"
                                  ? "text-green-500"
                                  : sortConfig.direction === "desc"
                                  ? "text-red-500"
                                  : "text-gray-500"
                                : "text-gray-500"
                            }`}
                          />
                        )} */}
                        {index === 1 && (
                          <FaSort
                            onClick={() => handleSortToggle("day")}
                            className={`ml-2 cursor-pointer ${
                              sortConfig.field === "day"
                                ? sortConfig.direction === "asc"
                                  ? "text-green-500"
                                  : sortConfig.direction === "desc"
                                  ? "text-red-500"
                                  : "text-gray-500"
                                : "text-gray-500"
                            }`}
                          />
                        )}
                        {index === 4 && (
                          <FaSort
                            onClick={() => handleSortToggle("status")}
                            className={`ml-2 cursor-pointer ${
                              sortConfig.field === "status"
                                ? sortConfig.direction === "asc"
                                  ? "text-green-500"
                                  : sortConfig.direction === "desc"
                                  ? "text-red-500"
                                  : "text-gray-500"
                                : "text-gray-500"
                            }`}
                          />
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="border-b border-l border-r border-t">
                {displayedData?.map((row: OrderReport, index: number) => (
                  <tr className="border-b text-center" key={index}>
                    <td className="min-w-[150px] border-r p-2">
                      {row.placeDetails?.name ? row.placeDetails?.name : "N/A"}
                    </td>
                    <td className="min-w-[150px] border-r">
                      <p className="text-black text-sm dark:text-white">
                        {row.day ? formatDate(row.day) : "N/A"}
                      </p>
                    </td>
                    <td className="min-w-[150px] border-r">
                      <p className="text-black text-sm dark:text-white">
                        {row.allSum ? `$${row.allSum}` : "N/A"}
                      </p>
                    </td>
                    <td className="min-w-[150px] border-r">
                      <p className="text-black text-sm dark:text-white">
                        {row.toBeTransfered ? `$${row.toBeTransfered}` : "N/A"}
                      </p>
                    </td>
                    <td className="min-w-[150px] border-r">
                      <p className="text-black text-sm dark:text-white">
                        {row.status ? `${row.status}` : "N/A"}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="flex h-64 items-center justify-center">
              <p className="text-xl font-semibold text-gray-500 dark:text-gray-400">
                No Reports found
              </p>
            </div>
          )}
          {displayedData.length > 0 && (
            <div className="mt-4 flex justify-end pb-4">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={`"bg-white text-[#71cb90]" }  rounded-md px-4
              py-2`}
              >
                <IoChevronBackCircleSharp
                  className={`h-6 w-6 ${
                    currentPage === 1 ? "text-gray-700" : "text-[#71cb90]"
                  }`}
                />
              </button>
              <button
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
                className={`"bg-white text-[#71cb90]" }  rounded-md px-4
              py-2`}
              >
                <IoChevronForwardCircleSharp
                  className={`h-6 w-6 ${
                    currentPage === totalPages
                      ? "text-gray-700"
                      : "text-[#71cb90]"
                  }`}
                />
              </button>
            </div>
          )}
        </div>
      </Card>
    </>
  );
}

export default ReportTable;
