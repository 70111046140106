import { useQuery } from "@tanstack/react-query";
import { getFlilteredOrders, getFlilteredOwnerOrders } from "api/orders/orders";
import { FilteredProductsPayload } from "interfaces/product/product";

export const useGetFilteredOrders = (payload?: FilteredProductsPayload) => {
  return useQuery(["orders"], () => getFlilteredOrders(payload));
};

export const useGetFilteredOwnerOrders = (
  payload?: FilteredProductsPayload
) => {
  return useQuery(["ownerOrders"], () => getFlilteredOwnerOrders(payload));
};
