import { Spinner } from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import { FormikProvider, useFormik } from "formik";
import { useForgotPassword } from "hooks/api/useForgotPassword";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .matches(/^[^@]+@[^@]+\.[^@]+$/, "Invalid email address")
    .required("Email is required"),
});

export const ForgotPasswordForm: React.FC = () => {
  const { mutate, isLoading } = useForgotPassword();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      mutate(
        {
          ...values,
        },
        {
          onSuccess: () => {
            formik.resetForm({
              values: { email: "" },
            });
          },
          onError: () => {
            formik.resetForm({
              values: { email: "" },
            });
          },
        }
      );
    },
  });

  const isFormInvalid = formik.isValid && formik.values.email;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <InputField
          {...formik.getFieldProps("email")}
          variant="auth"
          extra="mb-3"
          label="Email Adress*"
          placeholder="Enter your Email Adress"
          id="email"
          type="email"
          onChange={formik.handleChange}
        />
        <button
          className={`mt-2 w-full rounded-xl py-[12px] text-base font-medium text-white transition duration-200 
         ${
           !isFormInvalid
             ? "cursor-not-allowed bg-gray-400"
             : "bg-[#71cb90] hover:bg-[#71cb90] active:bg-[#71cb90]"
         }
         dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          disabled={!isFormInvalid}
        >
          {isLoading ? <Spinner /> : "Continue"}
        </button>
      </form>
    </FormikProvider>
  );
};
