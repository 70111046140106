import { useEffect, useState } from "react";
import Card from "components/card";
import { IoChevronForwardCircleSharp } from "react-icons/io5";
import { IoChevronBackCircleSharp } from "react-icons/io5";
import {
  Table,
  Tooltip,
  Modal,
  Text,
  Select,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
} from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { Order, OrderResponse } from "api/orders/orders";
import { IoMdEye } from "react-icons/io";
import { useGetOrdersById } from "hooks/orders/useGetFilteredOrdersById";
import { FaSort, FaUndo } from "react-icons/fa";
import { ShopResponse } from "interfaces/shop/shop";
import { SortType } from "interfaces/product/product";

function InvoicesTable(props: {
  tableData: OrderResponse;
  itemsPerPage: number;
  currentPage: number;
  handlePageChange: (pageNumber: number) => void;
  flowerShops: ShopResponse[];
  selectedId: string | null;
  handleShopChange: (id: string | null) => void;
  sortField: string;
  sortType: SortType;
  handleSortChange: (field: string, type: SortType) => void;
}) {
  const {
    tableData,
    currentPage,
    handlePageChange,
    flowerShops,
    selectedId,
    handleShopChange,
    sortField,
    sortType,
    handleSortChange,
  } = props;

  const totalPages = tableData.totalPages;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<Order | null>(null);
  const [displayedData, setDisplayedData] = useState<Order[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState<{
    field: string;
    direction: "asc" | "desc" | "default";
  }>({
    field: "",
    direction: "default",
  });

  useEffect(() => {
    setDisplayedData(tableData.results);
  }, [tableData, currentPage]);

  const columnNames = [
    "Creation Date",
    "Name",
    "Order Reference",
    "Order Amount (exc. GST)",
    "Tax Amount (exc. GST)",
    "Total Amount (exc. GST)",
    "View",
  ];

  const { data } = useGetOrdersById(selectedInvoice?._id ?? null);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const sortData = (
    data: Order[],
    {
      field,
      direction,
    }: { field: string; direction: "asc" | "desc" | "default" }
  ) => {
    if (direction === "default") return data;

    return data.sort((a, b) => {
      const aValue = a.placeId.name.toLowerCase();
      const bValue = b.placeId.name.toLowerCase();

      if (aValue < bValue) {
        return direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  useEffect(() => {
    const filteredData = tableData.results.filter((order) => 
    order.placeId.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (order.orderReferenceNumber && order.orderReferenceNumber.toString().toLowerCase().includes(searchTerm.toLowerCase()))
  );

    const sortedData =
      sortConfig.direction === "default"
        ? filteredData
        : sortData(filteredData, sortConfig);

    setDisplayedData(sortedData);
  }, [tableData, searchTerm, sortConfig]);

  const handleSortToggle = (columnName: string) => {
    const nextSortType =
      sortType === SortType.ASC ? SortType.DESC : SortType.ASC;
    handleSortChange(columnName, nextSortType);
  };

  const formatDate = (isoDate: string) => {
    if (!isoDate) return "N/A";
    const date = new Date(isoDate);
    return new Intl.DateTimeFormat("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }).format(date);
  };

  const openModal = (order: Order) => {
    setSelectedInvoice(order);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedInvoice(null);
  };

  return (
    <>
      <div className="mb-5 flex h-[40px] items-center rounded-full border-[1px] border-gray-200 bg-[white] text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[400px]">
        <p className="pl-3 pr-2 text-xl">
          <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
        </p>
        <input
          type="text"
          placeholder="Search by Flower Shop name and Order Reference..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="block h-full w-full rounded-full bg-[white] text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit xl:w-[400px]"
        />
      </div>
      <Card extra={"w-full h-full sm:overflow-auto px-6"}>
        <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            All Invoices
          </div>
          <div className="flex items-center gap-4">
            <Select
              value={selectedId || ""}
              onChange={(e) =>
                handleShopChange(e.target.value === "" ? null : e.target.value)
              }
              sx={{
                borderRadius: "0.75rem",
                height: "3.5em",
                fontSize: "14px",
                width: 60,
              }}
              className="border bg-white/0 outline-none"
              variant="auth"
              placeholder="Enter Type"
            >
              <option value="">All Shops</option>{" "}
              {Array.isArray(flowerShops) &&
                flowerShops.map((shop) => (
                  <option key={shop._id} value={shop._id}>
                    {shop.name}
                  </option>
                ))}
            </Select>
            {selectedId && (
              <FaUndo
                className="cursor-pointer"
                onClick={() => handleShopChange(null)}
              />
            )}
          </div>
        </header>
        {displayedData?.length > 0 ? (
          <>
            <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
              <Table className="w-full">
                <thead>
                  <tr className=" bg-gray-200">
                    {columnNames.map((columnName, index) => (
                      <th
                        key={index}
                        className={`cursor-pointer border-b-[1px] border-gray-200 pb-2 pl-2 pt-4 text-start ${
                          index === 0 ? "rounded-tl-md" : ""
                        } ${
                          index === columnNames.length - 1
                            ? "rounded-tr-md"
                            : ""
                        }`}
                      >
                        <div className="flex items-center justify-between text-[12px] text-[#a3aed0]">
                          {columnName}
                          {index === 0 && (
                            <FaSort
                              onClick={() => handleSortToggle("createdAt")}
                              className={`ml-2 cursor-pointer ${
                                sortConfig.field === "createdAt"
                                  ? sortConfig.direction === "asc"
                                    ? "text-green-500"
                                    : sortConfig.direction === "desc"
                                    ? "text-red-500"
                                    : "text-gray-500"
                                  : "text-gray-500"
                              }`}
                            />
                          )}
                          {/* {index === 1 && (
                            <FaSort
                              onClick={() => handleSortToggle("placeId.name")}
                              className={`ml-2 cursor-pointer ${
                                sortConfig.field === "placeId.name"
                                  ? sortConfig.direction === "asc"
                                    ? "text-green-500"
                                    : sortConfig.direction === "desc"
                                    ? "text-red-500"
                                    : "text-gray-500"
                                  : "text-gray-500"
                              }`}
                            />
                          )} */}
                          {index === 2 && (
                            <FaSort
                              onClick={() =>
                                handleSortToggle("orderReferenceNumber")
                              }
                              className={`ml-2 cursor-pointer ${
                                sortConfig.field === "orderReferenceNumber"
                                  ? sortConfig.direction === "asc"
                                    ? "text-green-500"
                                    : sortConfig.direction === "desc"
                                    ? "text-red-500"
                                    : "text-gray-500"
                                  : "text-gray-500"
                              }`}
                            />
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="border-b border-l border-r border-t ">
                  {displayedData?.map((row: Order, index: number) => (
                    <tr className="border-b text-center" key={index}>
                      <td className="min-w-[120px] border-r">
                        <p className="text-black text-sm dark:text-white">
                          {row.createdAt ? formatDate(row.createdAt) : "N/A"}
                        </p>
                      </td>
                      <td className="min-w-[150px] border-r p-5">
                        <Tooltip
                          label={row?.lineItems?.map(
                            (data) => data?.productId?.name
                          )}
                        >
                          <p className="text-black text-sm dark:text-white">
                            {row?.placeId?.name}
                          </p>
                        </Tooltip>
                      </td>
                      <td className="min-w-[120px] border-r">
                        <p className="text-black text-sm dark:text-white">
                          {row?.orderReferenceNumber
                            ? row.orderReferenceNumber
                            : "N/A"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-r">
                        <p className="text-black text-sm dark:text-white">
                          {row?.orderAmount
                            ? parseFloat(row?.orderAmount.toFixed(1))
                            : "N/A"}
                        </p>
                      </td>
                      <td className="min-w-[150px] border-r">
                        <p className="text-black text-sm dark:text-white">
                          {row.taxAmount
                            ? parseFloat(row?.taxAmount.toFixed(1))
                            : "N/A"}
                        </p>
                      </td>

                      <td className="min-w-[120px] border-r">
                        <p className="text-black text-sm dark:text-white">
                          {row.totalAmount
                            ? parseFloat(row.totalAmount.toFixed(1))
                            : "N/A"}
                        </p>
                      </td>
                      <td className="min-w-[40px] border-r pl-3">
                        <IoMdEye
                          className="h-5 w-5 cursor-pointer font-bold text-[#a3aed0] dark:text-white"
                          onClick={() => openModal(row)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {displayedData.length > 0 && (
                <div className="mt-4 flex justify-end pb-4">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={`"bg-white text-[#71cb90]" }  rounded-md px-4
              py-2`}
                  >
                    <IoChevronBackCircleSharp
                      className={`h-6 w-6 ${
                        currentPage === 1 ? "text-gray-700" : "text-[#71cb90]"
                      }`}
                    />
                  </button>
                  <button
                    disabled={currentPage === totalPages}
                    onClick={() => handlePageChange(currentPage + 1)}
                    className={`"bg-white text-[#71cb90]" }  rounded-md px-4
              py-2`}
                  >
                    <IoChevronForwardCircleSharp
                      className={`h-6 w-6 ${
                        currentPage === totalPages
                          ? "text-gray-700"
                          : "text-[#71cb90]"
                      }`}
                    />
                  </button>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="mb-4 flex h-full items-center justify-center">
            <p className="text-lg text-gray-500">No Invoices were found.</p>
          </div>
        )}
      </Card>
      <Modal isOpen={isOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent style={{ maxWidth: "80vw" }}>
          <ModalHeader>
            Invoice Details
            <span
              style={{ fontSize: "12px", fontWeight: 500, marginLeft: "10px" }}
            >
              (Creation Date: {formatDate(data?.createdAt)},
            </span>
            <span
              style={{ fontSize: "12px", fontWeight: 500, marginLeft: "10px" }}
            >
              Delivery Date: {formatDate(data?.deliveryDate)})
            </span>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedInvoice && (
              <>
                <Table className="mb-10  w-full">
                  <thead>
                    <tr className="!border-px !border-gray-400">
                      {[
                        "Order Reference",
                        "Order Items",
                        "Quantity",
                        "Price Per Unit (+Markup)",
                        "Total Price (+Markup)",
                      ].map((columnName, index) => (
                        <th
                          key={index}
                          className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                        >
                          <div className="items-center justify-between text-sm text-[#a3aed0]">
                            {columnName}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.lineItems?.map((items: any, index: number) => (
                      <tr key={index}>
                        <td className="min-w-[150px] border-white/0 py-3 pr-4">
                          <p className="text-black text-sm font-bold dark:text-white">
                            {data?.orderReferenceNumber}
                          </p>
                        </td>
                        <td className="min-w-[150px] border-white/0 py-3">
                          <p className="text-black w-[160px] break-words text-sm font-bold dark:text-white">
                            {items?.productId?.name}
                          </p>
                        </td>
                        <td className="min-w-[150px] border-white/0 py-3 pr-4">
                          <p className="text-black text-sm font-bold dark:text-white">
                            {items?.quantity}
                          </p>
                        </td>
                        <td className="min-w-[150px] border-white/0 py-3 pr-4">
                          <p className="text-black text-sm font-bold dark:text-white">
                            ${items?.wholesalePricePerUnit}
                          </p>
                        </td>
                        <td className="min-w-[150px] border-white/0 py-3 pr-4">
                          <p className="text-black text-sm font-bold dark:text-white">
                            ${items?.wholesalePrice || "N/A"}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Table className="mb-10  w-full">
                  <thead>
                    <tr className="!border-px !border-gray-400">
                      {[
                        "Message",
                        "Sub Total without Delivery",
                        "Delivery",
                        "Total GST",
                        "Total Price (GST) + Markup",
                      ].map((columnName, index) => (
                        <th
                          key={index}
                          className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                        >
                          <div className="items-center justify-between text-sm text-[#a3aed0]">
                            {columnName}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="min-w-[150px] border-white/0 py-3 pr-4">
                        <p className="text-black text-sm font-bold dark:text-white">
                          {data?.mediaItems?.map((item: any) => (
                            <p>
                              $
                              {item?.wholeSalePrice
                                ? parseFloat(item?.wholeSalePrice.toFixed(1))
                                : "N/A"}
                            </p>
                          ))}
                        </p>
                      </td>
                      <td className="min-w-[150px] border-white/0 py-3 pr-4">
                        <p className="text-black text-sm font-bold dark:text-white">
                          $
                          {data?.wholeSaleOrderAmount
                            ? parseFloat(data?.wholeSaleOrderAmount.toFixed(1))
                            : "N/A"}
                        </p>
                      </td>
                      <td className="min-w-[150px] border-white/0 py-3 pr-4">
                        <p className="text-black text-sm font-bold dark:text-white">
                          $
                          {data?.deliveryAmountWithMarkup
                            ? parseFloat(
                                data?.deliveryAmountWithMarkup.toFixed(1)
                              )
                            : "N/A"}
                        </p>
                      </td>
                      <td className="min-w-[150px] border-white/0 py-3 pr-4">
                        <p className="text-black text-sm font-bold dark:text-white">
                          $
                          {data?.taxAmount
                            ? parseFloat(data?.taxAmount.toFixed(2))
                            : "N/A"}
                        </p>
                      </td>
                      <td className="min-w-[150px] border-white/0 py-3 pr-4">
                        <p className="text-black text-sm font-bold dark:text-white">
                          $
                          {data?.totalAmount
                            ? parseFloat(data?.totalAmount.toFixed(2))
                            : "N/A"}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Table className="mb-10  w-full">
                  <thead>
                    <tr className="!border-px !border-gray-400">
                      {[
                        "User Email",
                        "Delivery Address",
                        "Creation Date",
                        "Delivery Date",
                        "",
                      ].map((columnName, index) => (
                        <th
                          key={index}
                          className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                        >
                          <div className="items-center justify-between text-sm text-[#a3aed0]">
                            {columnName}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="min-w-[150px] border-white/0 py-3">
                        <p className="text-black w-[200px] break-words text-sm font-bold dark:text-white">
                          {data?.createdByUserEmail
                            ? data?.createdByUserEmail
                            : ""}
                        </p>
                      </td>
                      <td className="min-w-[150px] border-white/0 py-3">
                        <p className="text-black w-[160px] break-words text-sm font-bold dark:text-white">
                          {data?.street}, {data?.zip}
                        </p>
                      </td>
                      <td className="min-w-[150px] border-white/0 py-3 pr-4">
                        <p className="text-black text-sm font-bold dark:text-white">
                          {formatDate(data?.createdAt)}
                        </p>
                      </td>
                      <td className="min-w-[150px] border-white/0 py-3 pr-4">
                        <p className="text-black text-sm font-bold dark:text-white">
                          {formatDate(data?.deliveryDate)}
                        </p>
                      </td>
                      <td className="min-w-[150px] border-white/0 py-3 pr-4"></td>
                    </tr>
                  </tbody>
                </Table>

                <div className="flex w-[20em] flex-row items-center gap-4">
                  <Text>
                    <strong>Status:</strong>
                  </Text>
                  <Select
                    disabled
                    sx={{
                      borderRadius: "0.75rem",
                      height: "3.5em",
                      fontSize: "14px",
                      width: "100%",
                    }}
                    className=" flex items-center  justify-center border  bg-white/0 outline-none  lg:w-[80%]"
                    variant="auth"
                    placeholder={data?.status}
                  />
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={closeModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default InvoicesTable;
