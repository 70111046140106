import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { signUp } from "api/auth/auth";
import { SignUpPayload } from "interfaces/auth/auth";

export const useSignUp = () => {
  const toast = useToast();
  return useMutation((payload: SignUpPayload) => signUp(payload), {
    onSuccess: () => {
      toast({
        title: "Account created.",
        description: "We've created your account for you.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: "Failed to create your account",
        description: "Try again to create your account",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });
};
