import { useMutation } from "@tanstack/react-query";
import { ResetPasswordPayload } from "../../interfaces/auth/auth";
import { resetPassword } from "../../api/auth/auth";
import { useToast } from "@chakra-ui/react";

export const useResetPassword = (code: string) => {
  const toast = useToast();
  return useMutation(
    (reset: ResetPasswordPayload) => resetPassword(reset, code),
    {
      onSuccess: () => {
        toast({
          title: "Password was successfully changed",
          description: "You can now Log In using your new password",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: "The password was not changed",
          status: "error",
          duration: 1000,
          isClosable: true,
        });
      },
    }
  );
};
