import { axiosInstance } from "api/config";
import { BaseUrl } from "enums/BaseUrl";
import { FilteredProductsPayload } from "interfaces/product/product";

export interface OrderReport {
  _id: string;
  day: string;
  allSum: number;
  toBeTransfered: number;
  placeDetails: {
    name?: string;
  };
  placeId: {
    name: string;
  };
  status: string;
  createdAt: string;
  updatedAt: string;
}

export interface OrderReportResult {
  results: OrderReport[];
  totalPages: number;
}

interface StatusPayload {
  id: string;
  status: string;
}

interface TotalSalesId {
  day?: number;
  month?: number;
  year?: number;
}

export interface TotalSalesType {
  _id: TotalSalesId;
  totalSales: number;
  monthName?: string;
  date?: string;
  dayName?: string;
  year?: number;
}

interface FlowerTypeDetails {
  _id: string;
  name: string;
}

interface Sells {
  count: number;
  flowerTypeDetails: FlowerTypeDetails[];
}

export interface LineItems {
  name: string;
  count: number;
}

export const getOrderReports = async (
  date?: string,
  placeId?: string
): Promise<OrderReport[]> => {
  let endpoint = `${BaseUrl.DEVELOPMENT}/orders-reports/get-reports`;

  if (date && placeId) {
    endpoint += `?date=${date}&placeId=${placeId}`;
  } else if (date) {
    endpoint += `?date=${date}`;
  } else if (placeId) {
    endpoint += `?placeId=${placeId}`;
  }

  const { data } = await axiosInstance.post(endpoint);

  return data;
};

export const getOrderReportsAdmin = async (
  payload?: FilteredProductsPayload
): Promise<OrderReportResult> => {
  const { data } = await axiosInstance.post(
    `${BaseUrl.DEVELOPMENT}/orders-reports/get-reports-admin`,
    payload
  );

  return data;
};

export const getOrderReportsOwner = async (
  payload?: FilteredProductsPayload
): Promise<OrderReportResult> => {
  const { data } = await axiosInstance.post(
    `${BaseUrl.DEVELOPMENT}/orders-reports/get-reports-owner`,
    payload
  );

  return data;
};

export const getReportsCount = async () => {
  const { data } = await axiosInstance.post<number>(
    `${BaseUrl.DEVELOPMENT}/orders-reports/get-order-count`
  );

  return data;
};

export const getReportsSoldProduct = async () => {
  const { data } = await axiosInstance.post<Sells[]>(
    `${BaseUrl.DEVELOPMENT}/orders-reports/get-most-sold-product`
  );

  return data;
};

export const getReportsSoldLineItem = async () => {
  const { data } = await axiosInstance.post<LineItems[]>(
    `${BaseUrl.DEVELOPMENT}/orders-reports/get-most-sold-line-item`
  );

  return data;
};

export const getTotalSales = async (period: string) => {
  const { data } = await axiosInstance.post<TotalSalesType[]>(
    `${BaseUrl.DEVELOPMENT}/orders-reports/get-total-sales?period=${period}`
  );

  return data;
};

export const changeReportStatus = async (report: StatusPayload) => {
  const { data } = await axiosInstance.patch(
    `${BaseUrl.DEVELOPMENT}/orders-reports/${report.id}/status`,
    { status: report.status }
  );

  return data;
};
