import { useEffect, useState } from "react";
import Card from "components/card";
import { IoChevronForwardCircleSharp } from "react-icons/io5";
import { IoChevronBackCircleSharp } from "react-icons/io5";
import { Select, Table, Tooltip } from "@chakra-ui/react";
import { GrFormEdit } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { FlowerProduct, FlowerResult } from "api/product/product";
import { FaSort, FaUndo } from "react-icons/fa";
import { ShopResponse } from "interfaces/shop/shop";
import { SortType } from "interfaces/product/product";

function ProductTable(props: {
  tableData: FlowerResult;
  itemsPerPage: number;
  currentPage: number;
  handlePageChange: (pageNumber: number) => void;
  flowerShops: ShopResponse[];
  selectedId: string | null;
  handleShopChange: (id: string | null) => void;
  sortField: string;
  sortType: SortType;
  handleSortChange: (field: string, type: SortType) => void;
}) {
  const navigate = useNavigate();
  const {
    tableData,
    currentPage,
    handlePageChange,
    handleShopChange,
    selectedId,
    flowerShops,
    sortField,
    sortType,
    handleSortChange,
  } = props;

  const handleEditClick = (_id: string) => {
    navigate(`/admin/product/edit-product/${_id}`);
  };
  const totalPages = tableData.totalPages;
  const [searchTerm, setSearchTerm] = useState("");
  const [displayedData, setDisplayedData] = useState<FlowerProduct[]>([]);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>(null);

  const columnNames = [
    "Flower Shop Name",
    "Product Name",
    "Description",
    "Size",
    "Price",
    "Edit",
  ];

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSort = (columnName: string) => {
    const nextSortType =
      sortType === SortType.ASC ? SortType.DESC : SortType.ASC;
    handleSortChange(columnName, nextSortType);
  };

  useEffect(() => {
    const filteredData = tableData.results.filter((product) => {
      const productNameMatch = product.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const flowerShopNameMatch = product.flowershopDetails[0]?.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      return productNameMatch || flowerShopNameMatch;
    });
    const sortedData = filteredData.sort((a, b) => {
      if (sortField === "Product Name") {
        return sortType === SortType.ASC
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      } else if (sortField === "Flower Shop Name") {
        return sortType === SortType.ASC
          ? a.flowershopDetails[0]?.name.localeCompare(
              b.flowershopDetails[0]?.name
            )
          : b.flowershopDetails[0]?.name.localeCompare(
              a.flowershopDetails[0]?.name
            );
      }
      return 0;
    });
    setDisplayedData(filteredData);
  }, [tableData, searchTerm, sortField, sortType]);

  return (
    <>
      <div className="mb-5 flex h-[40px] items-center rounded-full border-[1px] border-gray-200 bg-[white] text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[250px]">
        <p className="pl-3 pr-2 text-xl">
          <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
        </p>
        <input
          type="text"
          placeholder="Search by Flower Shop name..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="block h-full w-full rounded-full bg-[white] text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit xl:w-[200px]"
        />
      </div>
      <Card extra={"w-full h-full sm:overflow-auto px-6"}>
        <header className="relative flex items-center justify-between pt-4">
          <div className="flex w-full justify-between">
            <h1 className="text-xl font-bold text-navy-700 dark:text-white ">
              Owner Products
            </h1>
          </div>
          <div className="flex items-center gap-4">
            <Select
              value={selectedId || ""}
              onChange={(e) =>
                handleShopChange(e.target.value === "" ? null : e.target.value)
              }
              sx={{
                borderRadius: "0.75rem",
                height: "3.5em",
                fontSize: "14px",
                width: 60,
              }}
              className="border bg-white/0 outline-none"
              variant="auth"
              placeholder="Select Flower Shop"
            >
              <option value="">All Shops</option>{" "}
              {Array.isArray(flowerShops) &&
                flowerShops.map((shop) => (
                  <option key={shop._id} value={shop._id}>
                    {shop.name}
                  </option>
                ))}
            </Select>
            {selectedId && (
              <FaUndo
                className="cursor-pointer"
                onClick={() => handleShopChange(null)}
              />
            )}
          </div>
        </header>
        {displayedData.length > 0 ? (
          <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
            <Table className="w-full">
              <thead>
                <tr className="bg-gray-200">
                  {columnNames?.map((columnName, index) => (
                    <th
                      key={index}
                      className={`cursor-pointer border-b-[1px] border-gray-200 pb-2 pl-2 pt-4 text-start ${
                        index === 0 ? "rounded-tl-md" : ""
                      } ${
                        index === columnNames.length - 1 ? "rounded-tr-md" : ""
                      }`}
                    >
                      <div className="flex items-center justify-between text-sm text-[#a3aed0]">
                         {columnName}
  {columnName === "Product Name" ? (
    <FaSort
      className={`ml-2 cursor-pointer ${
        sortField === columnName
          ? sortType === SortType.ASC
            ? "text-green-500"
            : "text-red-500"
          : "text-gray-500"
      }`}
      onClick={() => handleSort("name")}
    />
  ) : null}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="border-b border-l border-r border-t ">
                {displayedData?.map((row: FlowerProduct) => (
                  <tr className="border-b text-center" key={row._id}>
                    <td className="min-w-[150px] border-r">
                      <p className="text-black text-sm dark:text-white">
                        {row.flowershopDetails[0]?.name ?? "N/A"}
                      </p>
                    </td>
                    <td className="min-w-[150px] border-r">
                      <p className="text-black text-sm dark:text-white">
                        {row.name}
                      </p>
                    </td>
                    <td className="min-w-[150px] border-r p-3">
                      <Tooltip label={row.desc}>
                        <p className="text-black text-sm dark:text-white">
                          {row.desc?.length > 30
                            ? `${row.desc?.substring(0, 30)}...`
                            : row.desc}
                        </p>
                      </Tooltip>
                    </td>

                    <td className="min-w-[150px] border-r">
                      {row.sizesWithPrices ? (
                        row.sizesWithPrices.map((size, index) => (
                          <p
                            key={index}
                            className="text-black text-sm dark:text-white"
                          >
                            {size.size ? size.size : "N/A"}
                          </p>
                        ))
                      ) : (
                        <p className="text-black text-sm dark:text-white">
                          N/A
                        </p>
                      )}
                    </td>
                    <td className="min-w-[150px] border-r">
                      <p className="text-black text-sm dark:text-white">
                        {row.currentPrice
                          ? `$${row.currentPrice}`
                          : row.sizesWithPrices
                          ? row.sizesWithPrices.map((size, index) => (
                              <span key={index}>
                                {size.price ? `$${size.price}` : "N/A"}
                                {index !== row.sizesWithPrices.length - 1 &&
                                  ",   "}
                              </span>
                            ))
                          : "N/A"}
                      </p>
                    </td>

                    <td className="min-w-[50px] border-r">
                      <GrFormEdit
                        onClick={() => handleEditClick(row._id)}
                        className="text-black ml-6 h-6 w-6 cursor-pointer font-bold dark:text-white"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
          <div className="flex h-64 items-center justify-center">
            <p className="text-xl font-semibold text-gray-500 dark:text-gray-400">
              No products found
            </p>
          </div>
        )}

        {displayedData.length > 0 && (
          <div className="mt-4 flex justify-end pb-4">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`"bg-white text-[#71cb90]" }  rounded-md px-4 py-2`}
            >
              <IoChevronBackCircleSharp
                className={`h-6 w-6 ${
                  currentPage === 1 ? "text-gray-700" : "text-[#71cb90]"
                }`}
              />
            </button>
            <button
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
              className={`"bg-white text-[#71cb90]" }  rounded-md px-4 py-2`}
            >
              <IoChevronForwardCircleSharp
                className={`h-6 w-6 ${
                  currentPage === totalPages
                    ? "text-gray-700"
                    : "text-[#71cb90]"
                }`}
              />
            </button>
          </div>
        )}
      </Card>
    </>
  );
}

export default ProductTable;
