import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { updateProfile } from "api/auth/auth";
import { UserUpdateObject } from "interfaces/auth/auth";

export const useUpdateProfile = () => {
  const toast = useToast();

  return useMutation((user: UserUpdateObject) => updateProfile(user), {
    onSuccess: () => {
      toast({
        title: "Profile informations were updated successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: "Failed to update the profile informations",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });
};
