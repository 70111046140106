import { FormLabel, Spinner, useToast } from "@chakra-ui/react";
import Card from "components/card";
import InputField from "components/fields/InputField";
import { FormikProvider, useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import { LuUpload } from "react-icons/lu";
import { useCreateShop } from "hooks/shop/useCreateShop";
import GoogleMap from "components/map/Map";
import { useUploadLogo } from "hooks/shop/useUploadLogo";
import { OpeningHours } from "./OpeningHours";
import { useGetAllShopById } from "hooks/shop/useGetShopById";
import { useAddOpeningHours } from "hooks/shop/useAddOpeningHours";
import { OpeningHoursPayload } from "interfaces/shop/shop";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  street: Yup.string().required("Required"),
  zip: Yup.string().required("Required"),
  phoneNumber: Yup.string().required("Required"),
});

export const AddBasicInfo: React.FC = () => {
  const toast = useToast();
  const { mutate, isLoading, data: response } = useCreateShop();
  const { data } = useGetAllShopById(String(response?._id));
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string | null>(null);
  const { mutate: mutateOpeningHours } = useAddOpeningHours(
    String(response?._id)
  );
  const uploadLogoMutation = useUploadLogo();
  const navigate = useNavigate();
  const [coordinates, setCoordinates] = useState({
    latitude: 42.6629,
    longitude: 21.1655,
  });
  const [formData, setFormData] = useState<OpeningHoursPayload[]>(() => {
    const defaultFormData: OpeningHoursPayload[] = [
      { day: "Monday", startTime: "", endTime: "", workDay: true },
      { day: "Tuesday", startTime: "", endTime: "", workDay: true },
      { day: "Wednesday", startTime: "", endTime: "", workDay: true },
      { day: "Thursday", startTime: "", endTime: "", workDay: true },
      { day: "Friday", startTime: "", endTime: "", workDay: true },
      { day: "Saturday", startTime: "", endTime: "", workDay: false },
      { day: "Sunday", startTime: "", endTime: "", workDay: false },
    ];

    if (data?.openingHours && data?.openingHours.length > 0) {
      return defaultFormData?.map((defaultDay) => {
        const existingDay = data?.openingHours.find(
          (existingDay) => existingDay.day === defaultDay.day
        );
        return existingDay || defaultDay;
      });
    }

    return defaultFormData;
  });

  const handleSwitchChange = (index: number) => {
    const newFormData = [...formData];
    if (newFormData[index].workDay) {
      newFormData[index] = {
        ...newFormData[index],
        workDay: false,
        startTime: "",
        endTime: "",
      };
    } else {
      newFormData[index] = {
        ...newFormData[index],
        workDay: !newFormData[index].workDay,
      };
    }
    setFormData(newFormData);
  };

  const handleTimeChange = (
    index: number,
    type: "start" | "end",
    value: string
  ) => {
    const newFormData = [...formData];
    newFormData[index] = {
      ...newFormData[index],
      [type === "start" ? "startTime" : "endTime"]: value,
    };
    setFormData(newFormData);
  };

  const handleSaveChanges = async (formData: OpeningHoursPayload[]) => {
    const isValid = formData
      .filter(
        (item) => item.workDay && item.startTime !== "" && item.endTime !== ""
      )
      .every((item) => item.startTime < item.endTime);

    if (!isValid) {
      toast({
        title: "Start time must be lower than end time for workdays!",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
      return;
    }

    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      mutateOpeningHours(formData);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      navigate("/admin/shop");
    } catch (error) {
      console.error("Error updating opening hours:", error);
    }
  };

  const handleUploadLogo = async (file: File | null, flowerShopId: string) => {
    try {
      if (!file) {
        console.log("No logo to upload.");
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedImageUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("flowerShopId", flowerShopId);
      await uploadLogoMutation.mutateAsync(formData);
      console.log("Logo uploaded successfully");
    } catch (error) {
      console.error("Error uploading logo:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      website: "",
      logo: null,
      aboutUs: "",
      phoneNumber: "",
      openingHours: formData,
      street: "",
      state: "Australia",
      city: "",
      zip: "",
      socialMedia: {
        facebook: "",
        insta: "",
        twitter: "",
        tiktok: "",
      },
      location: {
        type: "Point",
        coordinates: [coordinates.longitude, coordinates.latitude],
      },
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const shopId = response?._id;
        mutate({
          logo: null,
          ...values,
          openingHours: formData,
        });

        if (formik.values.logo) {
          await handleUploadLogo(formik.values.logo, shopId);
        }

        await handleSaveChanges(formData);
      } catch (error) {
        console.error("Error creating shop:", error);
        formik.resetForm();
      }
    },
  });

  useEffect(() => {
    if (response?._id && formData.length > 0 && formik.values.logo) {
      handleUploadLogo(formik.values.logo, String(response._id));
    }
  }, [response?._id, formData, formik.values.logo]);

  const isFormInvalid =
    formik.isValid &&
    formik.values.phoneNumber &&
    formik.values.name &&
    formik.values.city &&
    formik.values.state &&
    formik.values.street &&
    formik.values.zip;

  const handleBlur = async () => {
    const address = `${formik.values.street}, ${formik.values.city}, ${formik.values.state}, ${formik.values.zip}`;
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ address }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
        const location = results[0].geometry.location;
        const longitude = location.lng();
        const latitude = location.lat();

        formik.setFieldValue("location", {
          type: "Point",
          coordinates: [longitude, latitude],
        });
        setCoordinates({ longitude, latitude });
      } else {
        console.error(
          "Geocode was not successful for the following reason:",
          status
        );
      }
    });
  };

  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    formik.setFieldValue("logo", file);

    // Convert the dropped file to a data URL for preview
    const reader = new FileReader();
    reader.onload = () => {
      setUploadedImageUrl(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [".jpeg", ".png"],
    },
  });

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6 mb-4"}>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col  lg:flex-row">
            {/* Information */}
            <div className="w-full  lg:w-[50%]">
              <h1 className=" pb-2 pt-6 text-xl font-bold text-navy-700 dark:text-white">
                Shop Details
              </h1>
              <p className="pb-4 text-[13px] text-gray-500">
                Add shop information (Contact Information, Restaurant
                Description, Business Hours, Typical Check per Guest, Payment
                Options, Directions, Parking Info and Social Media Sites)
              </p>
              <div className="w-full">
                <div className="flex w-full flex-col justify-between gap-4 lg:flex-row">
                  <InputField
                    {...formik.getFieldProps("name")}
                    variant="auth"
                    extra="mb-3 lg:w-[50%]"
                    label="Shop Name*"
                    placeholder="Enter your Shop Name"
                    id="name"
                    type="text"
                    onChange={formik.handleChange}
                  />
                  <InputField
                    {...formik.getFieldProps("email")}
                    variant="auth"
                    extra="mb-3 lg:w-[50%]"
                    label="Email"
                    placeholder="Enter your Email"
                    id="email"
                    type="text"
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
              <div className="flex flex-col lg:flex-row lg:gap-4">
                <div className="flex w-full flex-col lg:flex-row ">
                  <div className="flex w-full justify-between gap-2">
                    <InputField
                      {...formik.getFieldProps("city")}
                      variant="auth"
                      extra="mb-3 lg:w-[50%]"
                      label="Adress*"
                      placeholder="City"
                      id="city"
                      type="text"
                      onChange={formik.handleChange}
                    />
                    <InputField
                      disabled
                      {...formik.getFieldProps("state")}
                      variant="auth"
                      value={"Australia"}
                      extra="mb-3 lg:w-[50%]"
                      placeholder="State"
                      id="state"
                      type="text"
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="w-full">
                  <div className="flex w-full flex-row justify-between gap-2">
                    <InputField
                      {...formik.getFieldProps("street")}
                      variant="auth"
                      extra="mb-3 w-[50%]"
                      placeholder="Street address"
                      id="streetAddress"
                      type="text"
                      onChange={formik.handleChange}
                    />
                    <InputField
                      {...formik.getFieldProps("zip")}
                      variant="auth"
                      extra="mb-3 w-[50%]"
                      placeholder="Zip Code"
                      id="zip"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div className="flex w-full flex-row justify-between gap-4">
                  <InputField
                    {...formik.getFieldProps("aboutUs")}
                    style={{ height: "5em" }}
                    variant="auth"
                    extra="mb-3 w-[100%] "
                    label="About the Shop"
                    placeholder="A brief informative section the provides users with an overview of the shop"
                    id="aboutUs"
                    type="text"
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
              <div className="flex w-full flex-col lg:flex-row">
                <div className="flex flex-col lg:w-[50%]">
                  <div className="w-full">
                    <InputField
                      {...formik.getFieldProps("website")}
                      variant="auth"
                      extra="mb-3 w-[100%] "
                      label="Website"
                      placeholder="The webiste URL of the shop"
                      id="website"
                      type="text"
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="mb-5 w-full ">
                    <FormLabel
                      style={{
                        fontSize: "14px",
                        paddingLeft: "0.3em",
                      }}
                    >
                      Phone Number*
                    </FormLabel>
                    <PhoneInput
                      {...formik.getFieldProps("phoneNumber")}
                      value={formik.values.phoneNumber}
                      inputStyle={{
                        marginTop: "0.5rem",
                        marginBottom: "5px",
                        display: "flex",
                        height: "3rem",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "0.75rem",
                        border: "1px solid #eceaea",
                        backgroundColor: "rgba(255, 255, 255, 0)",
                        padding: "0.75rem",
                        paddingLeft: "4em",
                        fontSize: "0.875rem",
                        outline: "none",
                      }}
                      country={"us"}
                      placeholder="Enter Last Name"
                      onChange={(value) =>
                        formik.setFieldValue("phoneNumber", value)
                      }
                    />
                  </div>
                </div>
                <div className="w-[50%] pl-4">
                  <FormLabel
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Upload your Logo
                  </FormLabel>
                  <div
                    {...getRootProps()}
                    className="flex h-[9em] flex-col items-center justify-center border-[1.5px] border-dashed bg-white/0 p-4 text-center"
                  >
                    <input {...getInputProps()} />
                    {!uploadedImageUrl && (
                      <>
                        <LuUpload
                          style={{ fontSize: "2em", color: "#979797" }}
                        />
                        <p className="text-[16px] font-medium text-[#979797]">
                          Upload Files
                        </p>
                        <p className="text-[12px] text-[#979797]">
                          PNG and JPG are allowed
                        </p>
                      </>
                    )}
                    {uploadedImageUrl && (
                      <img
                        src={uploadedImageUrl}
                        alt="Uploaded Logo"
                        className=" h-[10em] max-w-full"
                        style={{ width: "60%", height: "100%" }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row lg:gap-4">
                <div className="flex w-full flex-col lg:flex-row ">
                  <div className="flex w-full justify-between gap-2">
                    <InputField
                      {...formik.getFieldProps("socialMedia.insta")}
                      variant="auth"
                      extra="mb-3 w-[50%]"
                      label="Social Media"
                      placeholder="Instagram URL"
                      id="insta"
                      type="text"
                      onChange={formik.handleChange}
                    />
                    <InputField
                      {...formik.getFieldProps("socialMedia.facebook")}
                      variant="auth"
                      extra="mb-3 w-[50%]"
                      placeholder="Facebook URL"
                      id="facebook"
                      type="text"
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="w-full">
                  <div className="flex w-full flex-row justify-between gap-2">
                    <InputField
                      {...formik.getFieldProps("socialMedia.twitter")}
                      variant="auth"
                      extra="mb-3 w-[50%]"
                      placeholder="Twitter URL"
                      id="twitter"
                      type="text"
                      onChange={formik.handleChange}
                    />
                    <InputField
                      {...formik.getFieldProps("socialMedia.tiktok")}
                      variant="auth"
                      extra="mb-3 w-[50%]"
                      placeholder="Tiktok URL"
                      id="tiktok"
                      type="text"
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Map */}
            <div
              style={{ borderRadius: "10px" }}
              className="mb-[2em] mt-[5em] w-[50%] pl-10 "
            >
              <GoogleMap coordinates={coordinates} />
            </div>
          </div>
          <div className="flex flex-col gap-10">
            <OpeningHours
              {...formik.getFieldProps("openingHours")}
              handleSwitchChange={handleSwitchChange}
              handleTimeChange={handleTimeChange}
              formData={formData}
              setFormData={setFormData}
              data={data}
            />
          </div>
          <div className="flex justify-end pb-6">
            <button
              className={`mt-2 w-[5em] rounded-xl py-[12px] text-base font-medium text-white transition duration-200 
          ${
            !isFormInvalid
              ? "cursor-not-allowed bg-gray-400"
              : "bg-[#71cb90] hover:bg-[#71cb90] active:bg-[#71cb90]"
          }
          dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
              disabled={!isFormInvalid}
            >
              {isLoading ? <Spinner /> : "Save"}
            </button>
          </div>
        </form>
      </FormikProvider>
    </Card>
  );
};
