import React from "react";
import { ResetPasswordForm } from "./components/ResetPasswordForm";

interface ResetFormProps {
  code: string;
}

const ResetPassword: React.FC<ResetFormProps> = ({ code }) => {
  return (
    <div className="mb-16 mt-[10em] flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-xl font-bold text-navy-700 dark:text-white">
          Reset Password
        </h4>
        <ResetPasswordForm code={code} />
      </div>
    </div>
  );
};

export default ResetPassword;
