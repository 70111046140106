import { useEffect, useState } from "react";
import { APIProvider, Marker, Map } from "@vis.gl/react-google-maps";

function GoogleMap({ coordinates }: { coordinates?: any }) {
  const [viewState, setViewState] = useState({
    latitude: coordinates?.latitude || 42.6629 || "",
    longitude: coordinates?.longitude || 21.1655 || "",
    zoom: 15,
  });

  useEffect(() => {
    setViewState({
      latitude: coordinates?.latitude || 42.6629,
      longitude: coordinates?.longitude || 21.1655,
      zoom: 15,
    });
  }, [coordinates]);

  return (
    <APIProvider apiKey={"AIzaSyC_G7IciOmEtW5suL9vGSoeHYsMjOyXx5k"}>
      <Map
        center={{ lat: viewState.latitude, lng: viewState.longitude }}
        zoom={viewState.zoom}
      >
        <Marker
          position={{ lat: viewState.latitude, lng: viewState.longitude }}
        />
      </Map>
    </APIProvider>
  );
}

export default GoogleMap;
