import { axiosInstance } from "api/config";
import { BaseUrl } from "enums/BaseUrl";
import {
  DeletePhotoMenuPayload,
  OpeningHoursPayload,
  PhotoMenuResponse,
  ShopObject,
  ShopPayload,
  ShopResponse,
  UploadLogoResponse,
} from "interfaces/shop/shop";

export const getAllShops = async () => {
  const { data } = await axiosInstance.get<ShopResponse[]>(
    `${BaseUrl.DEVELOPMENT}/flower-shop/get-all-flowerShops`
  );

  return data;
};

export const getShopsById = async (id: string) => {
  const { data } = await axiosInstance.get<ShopResponse>(
    `${BaseUrl.DEVELOPMENT}/flower-shop/get-all-flower-shops-by-id?id=${id}`
  );

  return data;
};

export const getShopOwner = async () => {
  const { data } = await axiosInstance.get<ShopResponse[]>(
    `${BaseUrl.DEVELOPMENT}/flower-shop/get-flowerShop-owner`
  );

  return data;
};

export const uploadLogo = async (formData: FormData) => {
  const { data } = await axiosInstance.post<UploadLogoResponse>(
    `${BaseUrl.DEVELOPMENT}/flower-shop/upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return data;
};

export const createShop = async (
  payload: ShopPayload
): Promise<ShopResponse> => {
  const { data } = await axiosInstance.post<ShopResponse>(
    `${BaseUrl.DEVELOPMENT}/flower-shop/flower-shop`,
    payload
  );

  return data;
};

export interface ShippingPayload {
  fromInKm: number;
  toInKm: number;
  cost: number;
  flowerShopId: string;
}

export interface ShippingResponse {
  _id: string;
  fromInKm: number;
  toInKm: number;
  cost: number;
  flowerShopId: string;
}

export const addShipping = async (payload: ShippingPayload[]): Promise<any> => {
  const { data } = await axiosInstance.post<any>(
    `${BaseUrl.DEVELOPMENT}/flower-shop/shipping`,
    payload
  );

  return data;
};

export const deleteShipping = async (id: string): Promise<any> => {
  const { data } = await axiosInstance.delete<any>(
    `${BaseUrl.DEVELOPMENT}/flower-shop/shipping/${id}`
  );

  return data;
};

export const getShipping = async (
  flowerShopId: string
): Promise<ShippingResponse[]> => {
  const { data } = await axiosInstance.get<ShippingResponse[]>(
    `${BaseUrl.DEVELOPMENT}/flower-shop/shipping/${flowerShopId}?flowerShopId=${flowerShopId}`
  );

  return data;
};

export const updateShop = async (payload: ShopPayload) => {
  const { data } = await axiosInstance.put(
    `${BaseUrl.DEVELOPMENT}/flower-shop/update-flower-shop/${payload._id}`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};

export const updateShopOwner = async (payload: ShopPayload) => {
  const { data } = await axiosInstance.put(
    `${BaseUrl.DEVELOPMENT}/flower-shop/${payload._id}/update-flowerShop-owner`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};

export const addOpeningHours = async (
  id: string,
  openingHours: OpeningHoursPayload[]
) => {
  const { data } = await axiosInstance.put(
    `${BaseUrl.DEVELOPMENT}/flower-shop/${id}/add-opening-hours`,
    openingHours,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};

export const uploadMenuPhotos = async (shopId: string, formData: FormData) => {
  const { data } = await axiosInstance.post(
    `${BaseUrl.DEVELOPMENT}/gallery-menu/upload/restaurant/${shopId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return data;
};

export const getMenuPhotos = async (shopId: string) => {
  const { data } = await axiosInstance.get<PhotoMenuResponse>(
    `${BaseUrl.DEVELOPMENT}/gallery-menu/restaurant/${shopId}`
  );

  return data;
};

export const deleteMenuPhoto = async (payload: DeletePhotoMenuPayload) => {
  const { data } = await axiosInstance.delete(
    `${BaseUrl.DEVELOPMENT}/gallery-menu/restaurant/${payload.shopId}/media/${payload.id}`
  );

  return data;
};
