import { useQuery } from "@tanstack/react-query";
import { getFlilteredOrdersById, getOrdersById } from "api/orders/orders";
import { FilteredProductsPayload } from "interfaces/product/product";

export const useGetFilteredOrdersById = (
  orderId: string | null,
  payload?: FilteredProductsPayload
) => {
  return useQuery(
    ["ordersFilteredById", orderId],
    () => getFlilteredOrdersById(orderId, payload),
    {
      enabled: !!orderId,
    }
  );
};

export const useGetOrdersById = (orderId: string | null) => {
  return useQuery(["ordersById", orderId], () => getOrdersById(orderId), {
    enabled: !!orderId,
  });
};
