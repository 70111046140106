import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { updatePassword } from "api/auth/auth";
import { PasswordUpdateObject } from "interfaces/auth/auth";

export const useUpdatePassword = () => {
  const toast = useToast();
  return useMutation(
    (password: PasswordUpdateObject) => updatePassword(password),
    {
      onSuccess: () => {
        toast({
          title: "Password is updated successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: "Failed to update the password",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );
};
