import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createShop } from "api/shop/shop";
import { ShopPayload } from "interfaces/shop/shop";

export const useCreateShop = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation((payload: ShopPayload) => createShop(payload), {
    onSuccess: (_, variables, context: { refetch: () => void }) => {
      toast({
        title: "Shop was created successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      context?.refetch();
      queryClient.invalidateQueries(["shop"]);
    },
    onError: () => {
      toast({
        title: "Shop failed to create!",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    },
  });
};
