import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateProduct } from "api/product/product";
import { AddFlowerPayload } from "interfaces/product/product";

export const useUpdateProduct = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation((payload: AddFlowerPayload) => updateProduct(payload), {
    onSuccess: (_, variables, context: { refetch: () => void }) => {
      toast({
        title: "Product was updated successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      context?.refetch();
      queryClient.invalidateQueries(["products", variables._id]);
    },
    onError: () => {
      toast({
        title: "Product failed to update!",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    },
  });
};
