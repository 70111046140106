import { axiosInstance } from "api/config";
import { BaseUrl } from "enums/BaseUrl";
import {
  AuthResponse,
  RefreshTokenResponse,
  ForgotPasswordPayload,
  LoginPayload,
  LoginResponse,
  PasswordUpdateObject,
  ResetPasswordPayload,
  SignUpPayload,
  UserUpdateObject,
} from "interfaces/auth/auth";

export const validateToken = async (): Promise<LoginResponse> => {
  const { data } = await axiosInstance.get<LoginResponse>(
    `${BaseUrl.DEVELOPMENT}/auth/me`
  );

  return data;
};

export const refreshToken = async (refresh_token: string): Promise<RefreshTokenResponse> => {
  const { data } = await axiosInstance.post<RefreshTokenResponse>(
    `${BaseUrl.DEVELOPMENT}/auth/refresh-token`,
    {refresh_token:refresh_token}
  );
    
  return data;
};

export const login = async (payload: LoginPayload) => {
  const { data } = await axiosInstance.post<AuthResponse>(
    `${BaseUrl.DEVELOPMENT}/auth/signin`,
    payload
  );
  return data;
};

export const signUp = async (payload: SignUpPayload) => {
  const { data } = await axiosInstance.post(
    `${BaseUrl.DEVELOPMENT}/auth/signup`,
    payload
  );

  return data;
};

export const forgotPassword = async (payload: ForgotPasswordPayload) => {
  const { data } = await axiosInstance.post(
    `${BaseUrl.DEVELOPMENT}/recover`,
    payload
  );

  return data;
};

export const confirmEmail = async (code: string) => {
  const { data } = await axiosInstance.get(
    `${BaseUrl.DEVELOPMENT}/Verification/${code}`
  );

  return data;
};

export const resetPassword = async (
  reset: ResetPasswordPayload,
  code: string
) => {
  const { data } = await axiosInstance.post(
    `${BaseUrl.DEVELOPMENT}/recover/${code}`,
    reset
  );

  return data;
};

export const updateProfile = async (
  user: UserUpdateObject
): Promise<number> => {
  const { data } = await axiosInstance.put(
    `${BaseUrl.DEVELOPMENT}/settings/profile`,
    user.payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};

export const updatePassword = async (
  password: PasswordUpdateObject
): Promise<number> => {
  const { data } = await axiosInstance.post(
    `${BaseUrl.DEVELOPMENT}/auth/change-password`,
    password.payload
  );

  return data;
};
