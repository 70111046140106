import { axiosInstance } from "api/config";
import { BaseUrl } from "enums/BaseUrl";
import { Choices, ChoicesPayload } from "interfaces/globals/globals";

export interface Globals {
  _id: string;
  name: string;
  description: string;
  settingsItems: SettingsItems[];
}

export interface SettingsGlobals {
  _id: string;
  name: string;
  description: string;
  matchingSettings: SettingsItems[];
}

export interface SettingsItems {
  _id: string;
  name: string;
  desc: string;
}

export interface AddGlobalPayload {
  name: string;
  description?: string;
}

export interface AddItemPayload {
  name: string;
  desc: string;
}

export const getGlobals = async () => {
  const { data } = await axiosInstance.get<Globals[]>(
    `${BaseUrl.DEVELOPMENT}/flower-globals/allGlobals`
  );

  return data;
};

export const getAllGlobals = async () => {
  const { data } = await axiosInstance.get<SettingsGlobals[]>(
    `${BaseUrl.DEVELOPMENT}/flower-globals`
  );

  return data;
};

export const getChoices = async (id: string) => {
  const { data } = await axiosInstance.get<Choices[]>(
    `${BaseUrl.DEVELOPMENT}/flower-globals/${id}/choices`
  );

  return data;
};

export const addGlobal = async (payload: AddGlobalPayload) => {
  const { data } = await axiosInstance.post(
    `${BaseUrl.DEVELOPMENT}/flower-globals/globals`,
    payload
  );

  return data;
};

export const addItems = async (settingId: string, payload: AddItemPayload) => {
  const { data } = await axiosInstance.post(
    `${BaseUrl.DEVELOPMENT}/flower-globals/${settingId}/items`,
    payload
  );

  return data;
};

export const addChoices = async (
  flowerShopId: string,
  payload: ChoicesPayload[]
) => {
  const { data } = await axiosInstance.post(
    `${BaseUrl.DEVELOPMENT}/flower-globals/${flowerShopId}`,
    payload
  );

  return data;
};
