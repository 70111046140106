import { FormLabel, Input, Select, Spinner } from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import { FormikProvider, useFormik } from "formik";
import { useSignUp } from "hooks/api/useSignUp";
import { Role } from "interfaces/auth/auth";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  password: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email address").required("Required"),
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  phone_number: Yup.string().required("Required"),
  birthdate: Yup.date().required("Required"),
  gender: Yup.string().required("Required"),
});

export const SignUpForm: React.FC = () => {
  const { mutate, isLoading } = useSignUp();
  const navigate = useNavigate();
  const genders = ["male", "female", "other"];

  const formik = useFormik({
    initialValues: {
      password: "",
      email: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      birthdate: "",
      gender: "",
      state: "",
      street: "",
      city: "",
      zip: "",
      roles: [],
      address: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const dateOfBirthDate = new Date(values.birthdate);

      dateOfBirthDate.setMinutes(
        dateOfBirthDate.getMinutes() - dateOfBirthDate.getTimezoneOffset()
      );

      const utcDateOfBirth = dateOfBirthDate.toISOString();
      const combinedAddress = `${values.state}, ${values.street}, ${values.city}, ${values.zip}`;
      mutate(
        {
          ...values,
          birthdate: utcDateOfBirth,
          roles: [Role.PLACE_OWNER],
          address: combinedAddress,
        },
        {
          onSuccess: () => {
            navigate("/auth/login");
            formik.resetForm();
          },
          onError: () => {
            formik.resetForm();
          },
        }
      );
    },
  });

  const isFormInvalid =
    formik.values.first_name &&
    formik.values.last_name &&
    formik.values.gender &&
    formik.isValid &&
    formik.values.birthdate &&
    formik.values.phone_number &&
    formik.values.password &&
    formik.values.email;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex justify-center gap-[1.5em]">
          <InputField
            {...formik.getFieldProps("first_name")}
            variant="auth"
            extra="mb-3 w-[40em]"
            label="First Name*"
            placeholder="Enter First Name"
            id="first_name"
            type="text"
            onChange={formik.handleChange}
          />
          <InputField
            {...formik.getFieldProps("last_name")}
            variant="auth"
            extra="mb-3 w-[40em]"
            label="Last Name*"
            placeholder="Enter Last Name"
            id="last_name"
            type="text"
            onChange={formik.handleChange}
          />
        </div>
        <div className="flex  justify-center gap-[1.5em]">
          <div className="flex flex-col">
            <FormLabel
              style={{
                fontSize: "14px",
                paddingLeft: "0.3em",
              }}
            >
              Gender*
            </FormLabel>
            <Select
              sx={{
                borderRadius: "0.75rem",
                height: "3.5em",
                fontSize: "14px",
                width: "14em",
                "@media screen and (max-width: 940px)": {
                  width: "11em",
                },
              }}
              className="mb-3 flex  items-center justify-center  border bg-white/0  outline-none"
              {...formik.getFieldProps("gender")}
              variant="auth"
              placeholder="Enter Gender"
              id="gender"
            >
              {genders.map((gender) => (
                <>
                  <option key={gender} value={gender}>
                    {gender.charAt(0).toUpperCase() + gender.slice(1)}
                  </option>
                </>
              ))}
            </Select>
          </div>
          <div className="flex flex-col">
            <FormLabel
              style={{
                fontSize: "14px",
                paddingLeft: "0.3em",
              }}
            >
              Date of Birth*
            </FormLabel>
            <Input
              {...formik.getFieldProps("birthdate")}
              value={formik.values.birthdate}
              sx={{
                borderRadius: "0.75rem",
                height: "3.5em",
                fontSize: "14px",
                width: "14em",
                "@media screen and (max-width: 940px)": {
                  width: "11em",
                },
              }}
              placeholder="Select Date and Time"
              size="md"
              type="date"
            />
          </div>
        </div>
        <div className="flex  justify-center gap-[1.5em]">
          <div className="flex flex-col">
            <InputField
              {...formik.getFieldProps("state")}
              variant="auth"
              extra="mb-3 w-[5.5em]"
              label="State*"
              placeholder="Enter State"
              id="state"
              type="text"
              onChange={formik.handleChange}
            />
          </div>
          <div className="flex flex-col">
            <InputField
              {...formik.getFieldProps("street")}
              variant="auth"
              extra="mb-3 w-[5.5em]"
              label="Street*"
              placeholder="Enter Street"
              id="street"
              type="text"
              onChange={formik.handleChange}
            />
          </div>
          <div className="flex flex-col">
            <InputField
              {...formik.getFieldProps("city")}
              variant="auth"
              extra="mb-3 w-[5.5em]"
              label="City*"
              placeholder="Enter City"
              id="city"
              type="text"
              onChange={formik.handleChange}
            />
          </div>
          <div className="flex flex-col">
            <InputField
              {...formik.getFieldProps("zip")}
              variant="auth"
              extra="mb-3 w-[5.5em]"
              label="Zip*"
              placeholder="Enter Zip"
              id="zip"
              type="text"
              onChange={formik.handleChange}
            />
          </div>
        </div>

        <div className="mb-2">
          <FormLabel
            style={{
              fontSize: "14px",
              paddingLeft: "0.3em",
            }}
          >
            Phone Number*
          </FormLabel>
          <PhoneInput
            inputStyle={{
              marginTop: "0.5rem",
              display: "flex",
              height: "3rem",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "0.75rem",
              border: "1px solid #eceaea",
              backgroundColor: "rgba(255, 255, 255, 0)",
              padding: "0.75rem",
              paddingLeft: "4em",
              fontSize: "0.875rem",
              outline: "none",
            }}
            country={"us"}
            {...formik.getFieldProps("phone_number")}
            value={formik.values.phone_number}
            placeholder="Enter Last Name"
            onChange={(value) => formik.setFieldValue("phone_number", value)}
          />
        </div>
        <InputField
          {...formik.getFieldProps("email")}
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="Enter Email"
          id="email"
          type="email"
          onChange={formik.handleChange}
        />
        <InputField
          {...formik.getFieldProps("password")}
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Enter Password"
          id="password"
          type="password"
          onChange={formik.handleChange}
        />
        <button
          className={`mt-2 w-full rounded-xl py-[12px] text-base font-medium text-white transition duration-200 
          ${
            !isFormInvalid
              ? "cursor-not-allowed bg-gray-400"
              : "bg-[#71cb90] hover:bg-[#71cb90] active:bg-[#71cb90]"
          }
          dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          disabled={!isFormInvalid}
        >
          {isLoading ? <Spinner /> : "Sign Up"}
        </button>
      </form>
    </FormikProvider>
  );
};
