import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteShipping } from "api/shop/shop";

export const useDeleteShipping = () => {
  const queryClient = useQueryClient();

  return useMutation((id: string) => deleteShipping(id), {
    onSuccess: (_, variables, context: { refetch: () => void }) => {
      context?.refetch();
      queryClient.invalidateQueries(["shipping"]);
    },
  });
};
