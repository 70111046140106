import Card from "components/card";
import { useGetQR } from "hooks/qr-code/useGetQR";
import { ChangeEvent, useRef, useState } from "react";
import { MdVerifiedUser } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";

const QRCodeView = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { data } = useGetQR(orderId);
  const [codeInputs, setCodeInputs] = useState(["", "", "", ""]);
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  const handleCodeInputChange = (
    index: number,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    const newInputs = [...codeInputs];
    newInputs[index] = value;
    setCodeInputs(newInputs);

    // Automatically move to the next input field if a number is entered
    if (
      value.length === 1 &&
      /^\d+$/.test(value) &&
      index < inputRefs.current.length - 1
    ) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleCodeSubmit = () => {
    const code = codeInputs.join("");
    if (code.length === 4) {
      navigate(`/admin/order/${orderId}/${code}`);
    } else {
      alert("Please enter a 4-digit code.");
    }
  };

  console.log("data", data);

  return (
    <div className="flex w-full items-center justify-center">
      <Card extra="m-6 w-[50%] p-6">
        <div className="flex w-full flex-col items-center gap-5">
          {/* Page Header */}
          <h1 className="text-2xl font-semibold">Verification Code</h1>

          {/* Message Paragraph */}
          <p className="mt-3 text-gray-600">
            To proceed, please enter the verification code that has been sent to
            your email.
          </p>

          {/* Verification Icon */}
          <MdVerifiedUser size={80} className="mr-2 text-teal-700" />

          {/* Code Inputs */}
          <div className="mt-5 flex">
            {codeInputs.map((_, index) => (
              <input
                key={index}
                type="text"
                className="mx-1 h-12 w-12 rounded-md border border-gray-300 text-center"
                maxLength={1}
                value={codeInputs[index]}
                onChange={(e) => handleCodeInputChange(index, e)}
                ref={(el) => (inputRefs.current[index] = el)}
              />
            ))}
          </div>
          <button
            className="mt-3 rounded-md bg-blue-500 px-4 py-2 text-white"
            onClick={handleCodeSubmit}
          >
            Submit Code
          </button>
        </div>
      </Card>
    </div>
  );
};

export default QRCodeView;
