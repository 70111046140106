import { Spinner } from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import { FormikProvider, useFormik } from "formik";
import { useResetPassword } from "hooks/api/useResetPassword";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

interface ResetFormProps {
  code: string;
}

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const ResetPasswordForm: React.FC<ResetFormProps> = ({ code }) => {
  const { mutate, isLoading } = useResetPassword(String(code));
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      mutate(
        {
          ...values,
        },
        {
          onSuccess: () => {
            navigate("/auth/login");
            formik.resetForm();
          },
          onError: () => {
            formik.resetForm();
          },
        }
      );
    },
  });

  const isFormInvalid = !formik.isValid;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          {...formik.getFieldProps("password")}
          placeholder="Enter your New Passsword"
          id="password"
          type="password"
          onChange={formik.handleChange}
          error={formik.touched.password && formik.errors.password}
        />
        <InputField
          {...formik.getFieldProps("confirmPassword")}
          variant="auth"
          extra="mb-3"
          label="Confirm Password*"
          placeholder="Confirm your New Password"
          id="confirmPassword"
          type="password"
          onChange={formik.handleChange}
          error={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
        />

        <button
          type="submit"
          className={`mt-2 w-full rounded-xl py-[12px] text-base font-medium text-white transition duration-200 
    ${
      !isFormInvalid
        ? "bg-[#71cb90] hover:bg-[#71cb90] active:bg-[#71cb90]"
        : "cursor-not-allowed bg-gray-400"
    }
    dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          disabled={isFormInvalid}
        >
          {isLoading ? <Spinner /> : "Change Password"}
        </button>
      </form>
    </FormikProvider>
  );
};
