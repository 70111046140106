import React, { Component } from "react";
import Chart from "react-apexcharts";

type ChartProps = {
  chartData: any[];
  chartOptions: any;
};

type ChartState = {
  chartData: any[];
  chartOptions: any;
};

class ColumnChart extends Component<ChartProps, ChartState> {
  constructor(props: ChartProps) {
    super(props);
    this.state = {
      chartData: props.chartData,
      chartOptions: props.chartOptions,
    };
  }

  componentDidUpdate(prevProps: ChartProps) {
    if (
      prevProps.chartData !== this.props.chartData ||
      prevProps.chartOptions !== this.props.chartOptions
    ) {
      this.setState({
        chartData: this.props.chartData,
        chartOptions: this.props.chartOptions,
      });
    }
  }

  render() {
    return (
      <Chart
        options={this.state.chartOptions}
        series={this.state.chartData}
        type="bar"
        width="100%"
        height="100%"
      />
    );
  }
}

export default ColumnChart;
