import { useQuery } from "@tanstack/react-query";
import { getFlowersById, getFlowersByIdDashboard } from "api/product/product";

export const useGetFlowerById = (id: string) => {
  return useQuery(["productById"], () => getFlowersById(id));
};

export const useGetDashboardFlowerById = (id: string) => {
  return useQuery(["dashboardProductById"], () => getFlowersByIdDashboard(id));
};
