import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateShopOwner } from "api/shop/shop";
import { ShopPayload } from "interfaces/shop/shop";

export const useUpdateShopOwner = (flowerShopId: string) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation((payload: ShopPayload) => updateShopOwner(payload), {
    onSuccess: (_, variables, context: { refetch: () => void }) => {
      context?.refetch();
      queryClient.invalidateQueries(["allShops"]);
    },
    onError: () => {
      toast({
        title: "Shop failed to update!",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    },
  });
};
