import { useQuery } from "@tanstack/react-query";
import { getQR, getQRandCode } from "api/qr-code/qr-code";

export const useGetQR = (orderId: string) => {
  return useQuery(["qrCode"], () => getQR(orderId));
};

export const useGetQRandCode = (orderId: string, orderCode?: string) => {
  return useQuery(["qrCodeAndCode"], () => getQRandCode(orderId, orderCode));
};
