import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ShippingPayload, addShipping } from "api/shop/shop";

export const useAddShipping = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation((payload: ShippingPayload[]) => addShipping(payload), {
    onSuccess: (_, variables, context: { refetch: () => void }) => {
      toast({
        title: "Shipping was added successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      context?.refetch();
      queryClient.invalidateQueries(["shopOwner"]);
    },
    onError: () => {
      toast({
        title: "Shipping failed to be added!",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    },
  });
};
