import { AuthContext } from "contexts/AuthContext";
import { Role } from "interfaces/auth/auth";
import { useContext, useEffect, useState } from "react";
import Loader from "components/loader/Loader";
import { FilteredProductsPayload, SortType } from "interfaces/product/product";
import { useGetFilteredOrders } from "hooks/orders/useGetFilteredOrders";
import InvoicesTable from "./components/InvoicesTable";
import { useGetAllShops } from "hooks/shop/useGetAllShop";

const InvoicesView = () => {
  const [user] = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const { data: flowerShops } = useGetAllShops();
  const [sortField, setSortField] = useState<string>("createdAt");
  const [sortType, setSortType] = useState<SortType>(SortType.DESC);
  const itemsPerPage = 10;

  const filters: FilteredProductsPayload = {
    limit: itemsPerPage,
    page: currentPage,
    pageCount: true,
    filter: selectedId
      ? [
          {
            field: "placeId",
            operator: "In",
            value: [selectedId],
          },
        ]
      : [],
    sort: [
      {
        field: sortField,
        sortType: sortType,
      },
    ],
  };

  const {
    data: admin,
    isLoading: adminLoading,
    refetch: refetchAdmin,
  } = useGetFilteredOrders(filters);

  useEffect(() => {
    if (user.roles.some((role) => role === Role.ADMIN)) {
      refetchAdmin();
    } else {
    }
  }, [currentPage, selectedId, sortField, sortType]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleShopChange = (id: string | null) => {
    setSelectedId(id);
    setCurrentPage(1);
  };
  const handleSortChange = (field: string, type: SortType) => {
    setSortField(field);
    setSortType(type);
  };
  return (
    <div className="flex w-full flex-col gap-5">
      <div className="w-ful l mt-3 flex h-fit flex-col gap-5">
        <div className="col-span-4 lg:!mb-0">
          <>
            {user.roles.some((role) => role === Role.ADMIN) && (
              <>
                {admin?.results && admin?.results?.length > 0 ? (
                  <>
                    <InvoicesTable
                      itemsPerPage={itemsPerPage}
                      tableData={admin}
                      currentPage={currentPage}
                      sortField={sortField}
                      sortType={sortType}
                      flowerShops={flowerShops || []}
                      selectedId={selectedId}
                      handleShopChange={handleShopChange}
                      handlePageChange={handlePageChange}
                      handleSortChange={handleSortChange}
                    />
                  </>
                ) : adminLoading ? (
                  <Loader />
                ) : (
                  <div className="flex flex-col items-center justify-center pt-[15em]">
                    <p>There are no invoices.</p>
                  </div>
                )}
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default InvoicesView;
