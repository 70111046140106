import React from "react";
import { IoCloseCircle } from "react-icons/io5";
import { Comission } from "api/configs/configs";

interface AddComissionProps {
  comissionDetails: Comission[];
  onComissionChange: (index: number, field: string, value: number) => void;
  onAddComissionDetail: () => void;
  onRemoveComissionDetail: (index: number) => void;
}

export const AddComission: React.FC<AddComissionProps> = ({
  comissionDetails,
  onComissionChange,
  onAddComissionDetail,
  onRemoveComissionDetail,
}) => {
  return (
    <header className="relative flex cursor-pointer flex-col items-start justify-between pt-4">
      <p className="pb-4 text-sm font-bold">Add your Comission</p>
      {comissionDetails.map((detail, index) => (
        <div
          key={index}
          className="flex w-full flex-row items-center gap-4 pb-6"
        >
          <div className="flex-grow">
            <label className="text-sm text-navy-700 dark:text-white">
              From
            </label>
            <input
              placeholder="From"
              id={`from-${index}`}
              type="number"
              value={detail?.from ?? ""}
              onChange={(e) =>
                onComissionChange(index, "from", Number(e.target.value))
              }
              className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border p-3 text-sm outline-none"
            />
          </div>
          <div className="flex-grow">
            <label className="text-sm text-navy-700 dark:text-white">To</label>
            <input
              placeholder="To"
              id={`to-${index}`}
              type="number"
              value={detail?.to ?? ""}
              onChange={(e) =>
                onComissionChange(index, "to", Number(e.target.value))
              }
              className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border p-3 text-sm outline-none"
            />
          </div>
          <div className="flex flex-row items-center gap-6">
            <div className="flex-grow">
              <label className="text-sm text-navy-700 dark:text-white">
                Fee
              </label>
              <input
                placeholder="Fee"
                id={`fee-${index}`}
                type="number"
                value={detail?.fee ?? ""}
                onChange={(e) =>
                  onComissionChange(index, "fee", Number(e.target.value))
                }
                className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border p-3 text-sm outline-none"
              />
            </div>
            <IoCloseCircle
              size={30}
              className="text-black ml-[-1em] mt-6 flex h-12 cursor-pointer rounded-full outline-none"
              onClick={() => onRemoveComissionDetail(index)}
            />
          </div>
        </div>
      ))}

      <p
        onClick={onAddComissionDetail}
        className="cursor-pointer text-blue-500 underline hover:text-blue-700"
      >
        Add Shipping Detail
      </p>
    </header>
  );
};

export default AddComission;
