import { useEffect, useState } from "react";
import Card from "components/card";
import { IoChevronForwardCircleSharp } from "react-icons/io5";
import { IoChevronBackCircleSharp } from "react-icons/io5";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Image,
  Table,
  Text,
  Tooltip,
  Select,
  Box,
} from "@chakra-ui/react";
import { IoMdEye } from "react-icons/io";
import { FiSearch } from "react-icons/fi";
import { useGetOrdersById } from "hooks/orders/useGetFilteredOrdersById";
import { Order, OrderResponse } from "api/orders/orders";
import { FaSort, FaUndo } from "react-icons/fa";
import { ShopResponse } from "interfaces/shop/shop";
import { SortType } from "interfaces/product/product";

function OrdersTable(props: {
  tableData: OrderResponse;
  itemsPerPage: number;
  currentPage: number;
  handlePageChange: (pageNumber: number) => void;
  flowerShops: ShopResponse[];
  selectedId: string | null;
  handleShopChange: (id: string | null) => void;
  sortField: string;
  sortType: SortType;
  handleSortChange: (field: string, type: SortType) => void;
}) {
  const {
    tableData,
    currentPage,
    handlePageChange,
    handleShopChange,
    selectedId,
    flowerShops,
    sortField,
    sortType,
    handleSortChange,
  } = props;

  const totalPages = tableData.totalPages;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string | undefined>(
    undefined
  );
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [displayedData, setDisplayedData] = useState<Order[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState<{
    field: string;
    direction: "asc" | "desc" | "default";
  }>({
    field: "",
    direction: "default",
  });

  const statuses = [
    { id: 1, name: "DELIVERED", isVisible: true },
    { id: 2, name: "REFUNDED", isVisible: true },
    { id: 3, name: "IN_DELIVERY", isVisible: true },
    { id: 4, name: "PAID", isVisible: false },
    { id: 5, name: "CANCELLED", isVisible: false },
    { id: 6, name: "FAILED", isVisible: false },
    { id: 7, name: "DRAFT", isVisible: false },
  ];

  const { data } = useGetOrdersById(selectedOrder?._id ?? null);

  useEffect(() => {
    setDisplayedData(tableData.results);
  }, [tableData, currentPage]);

  useEffect(() => {
    if (selectedOrder) {
      setSelectedStatus(selectedOrder.status);
    }
  }, [selectedOrder]);

  const columnNames = [
    "Flower Shop Name",
    "Order Reference",
    "Created Date",
    "Delivery Date",
    "Total Amount (exc. GST)",
    "Status",
    "View",
  ];

  const openModal = (order: Order) => {
    setSelectedOrder(order);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedOrder(null);
    setSelectedStatus(undefined);
  };

  const sortData = (
    data: Order[],
    {
      field,
      direction,
    }: { field: string; direction: "asc" | "desc" | "default" }
  ) => {
    if (direction === "default") return data;

    return data.sort((a, b) => {
      const aValue = a.placeId.name.toLowerCase();
      const bValue = b.placeId.name.toLowerCase();

      if (aValue < bValue) {
        return direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  useEffect(() => {
    const filteredData = tableData.results.filter((order) =>
      order.placeId.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sortedData =
      sortConfig.direction === "default"
        ? filteredData
        : sortData(filteredData, sortConfig);

    setDisplayedData(sortedData);
  }, [tableData, searchTerm, sortConfig]);

  const handleSortToggle = (columnName: string) => {
    const nextSortType =
      sortType === SortType.ASC ? SortType.DESC : SortType.ASC;
    handleSortChange(columnName, nextSortType);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const formatDate = (isoDate: string) => {
    if (!isoDate) return "N/A";
    const date = new Date(isoDate);
    return new Intl.DateTimeFormat("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }).format(date);
  };

  return (
    <>
      <div className="mb-5 flex h-[40px] items-center rounded-full border-[1px] border-gray-200 bg-[white] text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[400px]">
        <p className="pl-3 pr-2 text-xl">
          <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
        </p>
        <input
          type="text"
          placeholder="Search by Flower Shop name and Order Reference..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="block h-full w-full rounded-full bg-[white] text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit xl:w-[400px]"
        />
      </div>
      <Card extra={"w-full h-full sm:overflow-auto px-6"}>
        <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            All Orders from Shops
          </div>
          <div className="flex items-center gap-4">
            <Select
              value={selectedId || ""}
              onChange={(e) =>
                handleShopChange(e.target.value === "" ? null : e.target.value)
              }
              sx={{
                borderRadius: "0.75rem",
                height: "3.5em",
                fontSize: "14px",
                width: 60,
              }}
              className="border bg-white/0 outline-none"
              variant="auth"
              placeholder="Select Flower Shop"
            >
              <option value="">All Shops</option>{" "}
              {Array.isArray(flowerShops) &&
                flowerShops.map((shop) => (
                  <option key={shop._id} value={shop._id}>
                    {shop.name}
                  </option>
                ))}
            </Select>
            {selectedId && (
              <FaUndo
                className="cursor-pointer"
                onClick={() => handleShopChange(null)}
              />
            )}
          </div>
        </header>

        <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
          {displayedData.length > 0 ? (
            <Table className="w-full">
              <thead>
                <tr className=" bg-gray-200">
                  {columnNames.map((columnName, index) => (
                    <th
                      key={index}
                      className={`cursor-pointer border-b-[1px] border-gray-200 pb-2 pl-2 pt-4 text-start ${
                        index === 0 ? "rounded-tl-md" : ""
                      } ${
                        index === columnNames.length - 1 ? "rounded-tr-md" : ""
                      }`}
                    >
                      <div className="flex items-center justify-between text-[12px] text-[#a3aed0]">
                        {columnName}
                        {/* {index === 0 && (
                          <FaSort
                            onClick={() => handleSortToggle("placeId.name")}
                            className={`ml-2 cursor-pointer ${
                              sortConfig.field === "placeId.name"
                                ? sortConfig.direction === "asc"
                                  ? "text-green-500"
                                  : sortConfig.direction === "desc"
                                  ? "text-red-500"
                                  : "text-gray-500"
                                : "text-gray-500"
                            }`}
                          />
                        )} */}
                        {index === 1 && (
                          <FaSort
                            onClick={() =>
                              handleSortToggle("orderReferenceNumber")
                            }
                            className={`ml-2 cursor-pointer ${
                              sortConfig.field === "orderReferenceNumber"
                                ? sortConfig.direction === "asc"
                                  ? "text-green-500"
                                  : sortConfig.direction === "desc"
                                  ? "text-red-500"
                                  : "text-gray-500"
                                : "text-gray-500"
                            }`}
                          />
                        )}
                        {index === 2 && (
                          <FaSort
                            onClick={() => handleSortToggle("createdAt")}
                            className={`ml-2 cursor-pointer ${
                              sortConfig.field === "createdAt"
                                ? sortConfig.direction === "asc"
                                  ? "text-green-500"
                                  : sortConfig.direction === "desc"
                                  ? "text-red-500"
                                  : "text-gray-500"
                                : "text-gray-500"
                            }`}
                          />
                        )}
                        {index === 3 && (
                          <FaSort
                            onClick={() => handleSortToggle("deliveryDate")}
                            className={`ml-2 cursor-pointer ${
                              sortConfig.field === "deliveryDate"
                                ? sortConfig.direction === "asc"
                                  ? "text-green-500"
                                  : sortConfig.direction === "desc"
                                  ? "text-red-500"
                                  : "text-gray-500"
                                : "text-gray-500"
                            }`}
                          />
                        )}
                        {index === 5 && (
                          <FaSort
                            onClick={() => handleSortToggle("status")}
                            className={`ml-2 cursor-pointer ${
                              sortConfig.field === "status"
                                ? sortConfig.direction === "asc"
                                  ? "text-green-500"
                                  : sortConfig.direction === "desc"
                                  ? "text-red-500"
                                  : "text-gray-500"
                                : "text-gray-500"
                            }`}
                          />
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="border-b border-l border-r border-t ">
                {displayedData?.map((row: Order, index: number) => (
                  <tr className="border-b text-center" key={index}>
                    <td className="min-w-[150px] border-r">
                      <Tooltip label={row.placeId?.name}>
                        <p className="text-black text-sm dark:text-white">
                          {row.placeId?.name?.length > 20
                            ? `${row.placeId.name?.substring(0, 20)}...`
                            : row.placeId?.name}
                        </p>
                      </Tooltip>
                    </td>
                    <td className="min-w-[150px] border-r p-3">
                      <Tooltip label={row.orderReferenceNumber}>
                        <p className="text-black text-sm dark:text-white">
                          {row.orderReferenceNumber?.length > 30
                            ? `${row.orderReferenceNumber?.substring(0, 30)}...`
                            : row.orderReferenceNumber}
                        </p>
                      </Tooltip>
                    </td>
                    <td className="min-w-[150px] border-r">
                      <p className="text-black text-sm dark:text-white">
                        {row.createdAt ? formatDate(row.createdAt) : "N/A"}
                      </p>
                    </td>
                    <td className="min-w-[150px] border-r">
                      <p className="text-black text-sm dark:text-white">
                        {row.deliveryDate
                          ? formatDate(row.deliveryDate)
                          : "N/A"}
                      </p>
                    </td>
                    <td className="min-w-[150px] border-r">
                      <p className="text-black text-sm dark:text-white">
                        {row.baseLineItemsPriceAmount && row.deliveryAmount
                          ? `$${
                              row.baseLineItemsPriceAmount + row.deliveryAmount
                            }`
                          : "N/A"}
                      </p>
                    </td>
                    <td className="min-w-[150px] border-r">
                      <p className="text-black text-sm dark:text-white">
                        {row.status ? `${row.status}` : "N/A"}
                      </p>
                    </td>
                    <td className="flex min-w-[50px] justify-center border-r py-3">
                      <IoMdEye
                        className="h-5 w-5 cursor-pointer items-center font-bold text-[#a3aed0] dark:text-white"
                        onClick={() => openModal(row)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="flex h-64 items-center justify-center">
              <p className="text-xl font-semibold text-gray-500 dark:text-gray-400">
                No Orders found
              </p>
            </div>
          )}

          {displayedData.length > 0 && (
            <div className="mt-4 flex justify-end pb-4">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={`"bg-white text-[#71cb90]" }  rounded-md px-4
              py-2`}
              >
                <IoChevronBackCircleSharp
                  className={`h-6 w-6 ${
                    currentPage === 1 ? "text-gray-700" : "text-[#71cb90]"
                  }`}
                />
              </button>
              <button
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
                className={`"bg-white text-[#71cb90]" }  rounded-md px-4
              py-2`}
              >
                <IoChevronForwardCircleSharp
                  className={`h-6 w-6 ${
                    currentPage === totalPages
                      ? "text-gray-700"
                      : "text-[#71cb90]"
                  }`}
                />
              </button>
            </div>
          )}
        </div>
      </Card>
      <Modal isOpen={isOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent style={{ maxWidth: "80vw" }}>
          <ModalHeader>Order Details #{data?.orderReferenceNumber}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedOrder && (
              <>
                <Table className="mb-10  w-full">
                  <thead>
                    <tr className="!border-px !border-gray-400">
                      {[
                        "Name",
                        "Description",
                        "Base Price (ex. GST)",
                        "Quantity",
                        "Product Type",
                        "Products Images",
                      ].map((columnName, index) => (
                        <th
                          key={index}
                          className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                        >
                          <div className="items-center justify-between text-sm text-[#a3aed0]">
                            {columnName}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.lineItems?.map((items: any, index: number) => (
                      <tr key={index}>
                        <td className="min-w-[150px] border-white/0 py-3 pr-4">
                          <p className="text-black text-sm font-bold dark:text-white">
                            {items?.productId?.name}
                          </p>
                        </td>
                        <td className="min-w-[150px] border-white/0 py-3 pr-4">
                          <Tooltip label={items?.productId?.desc}>
                            <p className="text-black text-sm font-bold dark:text-white">
                              {items?.productId?.desc?.length > 30
                                ? `${items?.productId?.desc.substring(
                                    0,
                                    30
                                  )}...`
                                : items?.productId?.desc}
                            </p>
                          </Tooltip>
                        </td>
                        <td className="min-w-[150px] border-white/0 py-3 pr-4">
                          <p className="text-black text-sm font-bold dark:text-white">
                            ${items?.basePrice}
                          </p>
                        </td>
                        <td className="min-w-[150px] border-white/0 py-3 pr-4">
                          <p className="text-black text-sm font-bold dark:text-white">
                            {items?.quantity}
                          </p>
                        </td>
                        <td className="min-w-[150px] border-white/0 py-3 pr-4">
                          <p className="text-black text-sm font-bold dark:text-white">
                            {items?.productType}
                          </p>
                        </td>
                        <td className="min-w-[150px] border-white/0 py-3 pr-4">
                          <Box display="flex" flexDirection="row" gap={2}>
                            {items?.productId?.file.map(
                              (image: any, index: number) => (
                                <Image
                                  w={10}
                                  h={10}
                                  key={index}
                                  src={image.fullPath}
                                  onClick={() =>
                                    setSelectedImage(image.fullPath)
                                  }
                                  cursor="pointer"
                                />
                              )
                            )}
                          </Box>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="mb-6 flex w-[50em] flex-row items-center gap-4">
                  <Text>
                    <strong>Delivery Address:</strong>
                  </Text>
                  <p>
                    {data?.street}, {data?.zip}
                  </p>
                </div>
                <div className="mb-6 flex w-[50em] flex-row items-center gap-4">
                  <Text>
                    <strong>Delivery Date:</strong>
                  </Text>
                  <p>
                    {data?.deliveryDate
                      ? formatDate(data?.deliveryDate)
                      : "N/A"}
                  </p>
                </div>
                <div className="mb-6 flex w-[50em] flex-row items-center gap-4">
                  <Text>
                    <strong>Delivery Amount (ex. GST):</strong>
                  </Text>
                  <p>${data?.deliveryAmount}</p>
                </div>
                <div className="flex w-[20em] flex-row items-center gap-4">
                  <Text>
                    <strong>Status:</strong>
                  </Text>
                  <Select
                    disabled
                    sx={{
                      borderRadius: "0.75rem",
                      height: "3.5em",
                      fontSize: "14px",
                      width: "100%",
                    }}
                    className=" flex items-center  justify-center border  bg-white/0 outline-none  lg:w-[80%]"
                    variant="auth"
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                  >
                    {statuses.map((status) => (
                      <option
                        key={status.id}
                        value={status.name}
                        disabled={!status.isVisible}
                      >
                        {status.isVisible
                          ? `${status.name.charAt(0).toUpperCase()}${status.name
                              .slice(1)
                              .toLowerCase()}`
                          : status.name.charAt(0).toUpperCase() +
                            status.name.slice(1).toLowerCase()}
                      </option>
                    ))}
                  </Select>
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={closeModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {selectedImage && (
        <Modal isOpen={!!selectedImage} onClose={() => setSelectedImage(null)}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <Image src={selectedImage} />
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

export default OrdersTable;
