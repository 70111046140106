import { IoDocuments } from "react-icons/io5";
import { MdBarChart } from "react-icons/md";
import { useGetMostSold } from "hooks/reports/useGetMosSoldProducts";
import Widget from "components/widget/Widget";
import CheckTable from "views/admin/default/components/CheckTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import { useGetOrderReportCount } from "hooks/reports/useGetReportCount";
import TotalSales from "./components/TotalSales";
import { useGetTotalSales } from "hooks/reports/useGetTotalSales";
import { useContext, useEffect, useState } from "react";
import { FilteredProductsPayload, SortType } from "interfaces/product/product";
import { AuthContext } from "contexts/AuthContext";
import { Role } from "interfaces/auth/auth";
import {
  useGetOrderPeportsAdmin,
  useGetOrderPeportsOwner,
} from "hooks/orders/useGetOrderReportsAdmin";
import { useGetShopOwners } from "hooks/shop/useGetShopOwner";
import OwnerTable from "./components/OwnerTable";
import {
  useGetFilteredOrders,
  useGetFilteredOwnerOrders,
} from "hooks/orders/useGetFilteredOrders";
import AdminTable from "./components/AdminTable";
import { useGetMostSoldLineItem } from "hooks/reports/useGetMostSoldListItem";
import { LineItems } from "api/reports/reports";

const Dashboard = () => {
  const [user] = useContext(AuthContext);
  const [period, setPeriod] = useState("day");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const { data: orderReportsCount } = useGetOrderReportCount();
  const { data: mostSold } = useGetMostSold();
  const {
    data: totalSales,
    isLoading,
    refetch,
  } = useGetTotalSales(String(period));
  const { data: mostSoldItem } = useGetMostSoldLineItem();

  const mostSoldLineItem = mostSoldItem?.reduce(
    (max: LineItems | null, item: LineItems) =>
      max && item.count > max.count ? item : max,
    mostSoldItem && mostSoldItem[0]
  );

  const handlePeriodChange = (newPeriod: string) => {
    setPeriod(newPeriod);
  };

  const filters: FilteredProductsPayload = {
    limit: itemsPerPage,
    page: currentPage,
    pageCount: true,
    filter: [],
    sort: [
      {
        field: "createdAt",
        sortType: SortType.DESC,
      },
    ],
  };

  const { data: admin } = useGetFilteredOrders(filters);

  const { data: placeOwner, refetch: refetchOwner } =
    useGetFilteredOwnerOrders(filters);

  useEffect(() => {
    refetch();
  }, [period, refetch]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const totalSalesSum = Array.isArray(totalSales)
    ? totalSales.reduce((sum, item) => sum + item.totalSales, 0)
    : 0;
  const formattedTotalSalesSum = totalSalesSum.toFixed(3);

  return (
    <div>
      {/* Card widget */}

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"All Orders"}
          subtitle={orderReportsCount}
        />
        <Widget
          icon={<IoDocuments className="h-6 w-6" />}
          title={"Mos Sold Product"}
          subtitle={
            mostSoldLineItem ? mostSoldLineItem.name : "No Sold Product"
          }
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Total Sales"}
          subtitle={`$${formattedTotalSalesSum}`}
        />
      </div>

      {/* Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <DailyTraffic mostSold={mostSold} />
        <TotalSales
          isLoading={isLoading}
          salesData={totalSales}
          onPeriodChange={handlePeriodChange}
        />
      </div>

      {/* Tables & Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-1">
        <div>
          {user.roles.some((role) => role === Role.PLACE_OWNER) && (
            <OwnerTable
              itemsPerPage={itemsPerPage}
              tableData={placeOwner}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
            />
          )}
          {user.roles.some((role) => role === Role.ADMIN) && (
            <AdminTable
              itemsPerPage={itemsPerPage}
              tableData={admin}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
