import { useMutation } from "@tanstack/react-query";
import { confirmEmail } from "../../api/auth/auth";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const useConfirmEmail = (code: string) => {
  const toast = useToast();
  const navigate = useNavigate();
  return useMutation(() => confirmEmail(code), {
    onSuccess: () => {
      toast({
        title: "Email was confirmed",
        description: "You can now log in within the app",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate(`/auth/login`);
    },
    onError: () => {
      toast({
        title: "The email was not confirmed!",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    },
  });
};
