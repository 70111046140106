import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AddConfigs, addConfig } from "api/configs/configs";

export const useAddConfig = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation((payload: AddConfigs) => addConfig(payload), {
    onSuccess: (_, variables, context: { refetch: () => void }) => {
      toast({
        title: "Configs were updated successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      context?.refetch();
      queryClient.invalidateQueries(["configs"]);
    },
    onError: () => {
      toast({
        title: "Configs failed to be updated!",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    },
  });
};
