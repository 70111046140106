import { useEffect, useState } from "react";
import Card from "components/card";
import { Table, Tooltip } from "@chakra-ui/react";
import { Order, OrderResponse } from "api/orders/orders";

function OwnerTable(props: {
  tableData: OrderResponse;
  itemsPerPage: number;
  currentPage: number;
  handlePageChange: (pageNumber: number) => void;
}) {
  const { tableData, itemsPerPage, currentPage } = props;

  const [displayedData, setDisplayedData] = useState<Order[]>([]);

  console.log("displayes", displayedData);

  useEffect(() => {
    setDisplayedData(tableData?.results);
  }, [tableData, currentPage]);

  const columnNames = [
    "Flower Shop Name",
    "Order Reference",
    "Created Date",
    "Delivery Date",
    "Total Amount (exc. GST)",
    "Status",
  ];

  const formatDate = (isoDate: string) => {
    if (!isoDate) return "N/A";
    const date = new Date(isoDate);
    return new Intl.DateTimeFormat("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }).format(date);
  };

  return (
    <>
      <Card extra={"w-full h-full sm:overflow-auto px-6"}>
        <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Latest Owner Orders
          </div>
        </header>

        <div className="mb-6 mt-8 overflow-x-scroll xl:overflow-x-hidden">
          <Table className="w-full">
            <thead>
              <tr className=" bg-gray-200">
                {columnNames?.map((columnName, index) => (
                  <th
                    key={index}
                    className={`cursor-pointer border-b-[1px] border-gray-200 pb-2 pl-2 pt-4 text-start ${
                      index === 0 ? "rounded-tl-md" : ""
                    } ${
                      index === columnNames.length - 1 ? "rounded-tr-md" : ""
                    }`}
                  >
                    <div className="items-center justify-between text-sm text-[#a3aed0]">
                      {columnName}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="border-b border-l border-r border-t ">
              {displayedData?.slice(0, 5).map((row: Order, index: number) => (
                <tr className="border-b text-center" key={index}>
                  <td className="min-w-[150px] border-r">
                    <p className="text-black text-sm dark:text-white">
                      {row.placeId.name ? row.placeId.name : "N/A"}
                    </p>
                  </td>
                  <td className="min-w-[150px] border-r p-3">
                    <Tooltip label={row.orderReferenceNumber}>
                      <p className="text-black text-sm dark:text-white">
                        {row.orderReferenceNumber?.length > 30
                          ? `${row.orderReferenceNumber?.substring(0, 30)}...`
                          : row.orderReferenceNumber}
                      </p>
                    </Tooltip>
                  </td>
                  <td className="min-w-[150px] border-r">
                    <p className="text-black text-sm dark:text-white">
                      {row.createdAt ? formatDate(row.createdAt) : "N/A"}
                    </p>
                  </td>
                  <td className="min-w-[150px] border-r">
                    <p className="text-black text-sm dark:text-white">
                      {row.deliveryDate ? formatDate(row.deliveryDate) : "N/A"}
                    </p>
                  </td>
                  <td className="min-w-[150px] border-r">
                    <p className="text-black text-sm dark:text-white">
                      {row.baseLineItemsPriceAmount && row.deliveryAmount
                        ? `$${
                            row.baseLineItemsPriceAmount + row.deliveryAmount
                          }`
                        : "N/A"}
                    </p>
                  </td>
                  <td className="min-w-[150px] border-r">
                    <p className="text-black text-sm dark:text-white">
                      {row.status ? `${row.status}` : "N/A"}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Card>
    </>
  );
}

export default OwnerTable;
