import Loader from "components/loader/Loader";
import { TokenContext } from "contexts/AuthContext";
import { Navigate, Route, Routes } from "react-router-dom";
import { Suspense, useContext, useEffect, useState } from "react";
import { useToken } from "hooks/api/useToken";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import ForgotPasswordLayout from "layouts/auth/forgotPassword";
import ResetPasswordLayout from "layouts/auth/resetPassword";
import ConfirmEmailLayout from "layouts/auth/confirmEmail";
import SignUp from "layouts/auth/signUp";
import AddShopView from "views/admin/shop/addShop";
import EditBasicInformationView from "views/admin/shop/editBasicInfo";
import AddProductView from "views/admin/product/addProduct";
import EditProductView from "views/admin/product/editProduct";
import AdminEditInformationView from "views/admin/shop/adminEditInfo";
import QRCodeView from "views/admin/qr-code";
import OrderMessageView from "views/admin/qr-code/orderMessage";
import Profile from "layouts/admin/profile";

export const Router: React.FC = () => {
  const { token } = useContext(TokenContext);
  const { data: response, error } = useToken();

  const [userLoaded, setUserLoaded] = useState(false);

  useEffect(() => {
    if (response || error) {
      setUserLoaded(true);
    }
  }, [response, error]);

  const isAuthenticated = token.AuthenticationResult.IdToken;

  if (!userLoaded) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {!isAuthenticated && (
          <>
            <Route path="auth/login" element={<AuthLayout />} />
            <Route path="auth/sign-up" element={<SignUp />} />
            <Route
              path="auth/forgot-password"
              element={<ForgotPasswordLayout />}
            />
            <Route path="/recovery/:code" element={<ResetPasswordLayout />} />
            <Route
              path="/verification/:code"
              element={<ConfirmEmailLayout />}
            />
            {/* Redirect from admin/default to auth/login if not authenticated */}
            <Route
              path="admin/*"
              element={<Navigate to="/auth/login" replace />}
            />
          </>
        )}
        {isAuthenticated && (
          <>
            <Route path="admin/*" element={<AdminLayout />} />
            {/* Redirect from auth pages to admin if authenticated */}
            <Route path="auth/*" element={<Navigate to="/admin" replace />} />
            <Route path="admin/shop/add-shop" element={<AddShopView />} />
            <Route
              path="admin/products/add-product"
              element={<AddProductView />}
            />
            <Route path="admin/profile" element={<Profile />} />
            <Route
              path="admin/shop/approve-shop/:id"
              element={<AdminEditInformationView />}
            />
            <Route
              path="admin/shop/edit-shop/:id"
              element={<EditBasicInformationView />}
            />
            QRCodeView
            <Route
              path="admin/product/edit-product/:id"
              element={<EditProductView />}
            />
            <Route
              path="admin/verify-order/:orderId"
              element={<QRCodeView />}
            />
            <Route
              path="admin/order/:orderId/:code"
              element={<OrderMessageView />}
            />
          </>
        )}
        {/* Fallback for any other routes */}
        <Route
          path="*"
          element={
            <Navigate
              to={isAuthenticated ? "admin/default" : "/auth/login"}
              replace
            />
          }
        />
      </Routes>
    </Suspense>
  );
};
