import { Switch as ChakraSwitch } from "@chakra-ui/react";
import { OpeningHoursPayload, ShopResponse } from "interfaces/shop/shop";

interface OpeningHoursProps {
  data: ShopResponse;
  handleTimeChange: any;
  handleSwitchChange: any;
  formData: OpeningHoursPayload[];
  setFormData: React.Dispatch<React.SetStateAction<OpeningHoursPayload[]>>;
}

export const OpeningHours: React.FC<OpeningHoursProps> = ({
  formData,
  handleSwitchChange,
  handleTimeChange,
}) => {
  return (
    <>
      <header
        className={` relative flex cursor-pointer flex-row items-center justify-between pt-4`}
      >
        <div className="w-full">
          <h1 className={`pb-4 text-lg font-bold`}>
            Tell guests when you are open
          </h1>
          {formData.map((item, index) => (
            <div
              className={`flex w-[60%] flex-row items-center pb-2`}
              key={index}
            >
              <div className="flex w-full flex-row items-center gap-4 ">
                <ChakraSwitch
                  isChecked={item.workDay}
                  onChange={() => handleSwitchChange(index)}
                />
                <p className={`text-sm font-medium`}>{item.day}</p>
              </div>
              <div className="relative flex flex-row gap-4">
                <input className="hidden" type="time" />
                <input
                  type="time"
                  id={`start-${index}`}
                  name={`start-${index}`}
                  min="05:00"
                  max="23:00"
                  placeholder="From"
                  value={item.startTime}
                  onChange={(e) =>
                    handleTimeChange(index, "start", e.target.value)
                  }
                  className="mt-2 flex h-12 items-center justify-center rounded-xl border bg-white/0 p-3 pl-[10em] text-right text-sm"
                />
                <input
                  type="time"
                  id={`end-${index}`}
                  name={`end-${index}`}
                  min="05:00"
                  max="23:00"
                  placeholder="To"
                  value={item.endTime}
                  onChange={(e) =>
                    handleTimeChange(index, "end", e.target.value)
                  }
                  className="mt-2 flex h-12 items-center justify-center rounded-xl border bg-white/0 p-3 pl-[10em] text-right text-sm"
                />
              </div>
            </div>
          ))}
        </div>
      </header>
    </>
  );
};
