import React, { ChangeEvent, InputHTMLAttributes } from "react";
import { useField } from "formik";

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: string;
  extra: string;
  variant: string;
  error?: string | undefined;
  disabled?: boolean;
  style?: React.CSSProperties;
}

function InputField({
  label,
  id,
  extra,
  type,
  placeholder,
  variant,
  error,
  disabled,
  onChange,
  style,
  ...rest
}: InputFieldProps) {
  const name = rest.name || id; // Set the name to id if not provided
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
  };
  const [, meta] = useField({ ...rest, name });

  const isError = meta.touched && meta.error;
  const errorClass = error ? "border-red-500 bg-red-100" : "";
  const disabledClass = disabled
    ? "cursor-not-allowed bg-gray-100 opacity-50"
    : "";

  return (
    <div className={`${extra}`}>
      <label
        htmlFor={id}
        className={`text-sm text-navy-700 dark:text-white ${
          variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"
        }`}
      >
        {label}
      </label>
      <input
        {...rest}
        onChange={handleChange}
        disabled={disabled}
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border p-3 text-sm outline-none ${errorClass} ${disabledClass}`}
        style={style}
      />

      {isError && <p className="mt-2 text-sm text-red-600">{meta.error}</p>}
    </div>
  );
}

export default InputField;
