import React from "react";
import { Select } from "@chakra-ui/react";
import { ShopResponse } from "interfaces/shop/shop";
import { ShippingPayload } from "api/shop/shop";
import { IoCloseCircle } from "react-icons/io5";

interface AddAveragePriceProps {
  data?: ShopResponse[];
  shippingDetails: ShippingPayload[];
  onShippingChange: (index: number, field: string, value: number) => void;
  onAddShippingDetail: () => void;
  onRemoveShippingDetail: (index: number) => void;
  selectedShopId: string;
  onShopChange: (shopId: string) => void;
}

export const AddAveragePrice: React.FC<AddAveragePriceProps> = ({
  data,
  shippingDetails,
  onShippingChange,
  onAddShippingDetail,
  onRemoveShippingDetail,
  selectedShopId,
  onShopChange,
}) => {
  const isShopSelected = selectedShopId !== "";

  return (
    <header className="relative flex cursor-pointer flex-col items-start justify-between pt-4">
      <div style={{ marginBottom: "50px", width: "45%" }}>
        <p className="pb-2 text-sm font-bold">Select your shop</p>
        <Select
          value={selectedShopId}
          onChange={(e) => onShopChange(e.target.value)}
        >
          <option value="" disabled>
            Select a shop
          </option>
          {data &&
            data?.map((shop) => (
              <option key={shop._id} value={shop._id}>
                {shop.name}
              </option>
            ))}
        </Select>
      </div>
      <p className="pb-4 text-sm font-bold">
        Add your shop range in KM's and cost
      </p>
      {shippingDetails?.map((detail, index) => (
        <div
          key={index}
          className="flex w-full flex-row items-center gap-4 pb-6"
        >
          <div className="flex-grow">
            <label className="text-sm text-navy-700 dark:text-white">
              From
            </label>
            <input
              placeholder="From"
              id={`fromInKm-${index}`}
              type="number"
              value={detail?.fromInKm ?? ""}
              onChange={(e) =>
                onShippingChange(index, "fromInKm", Number(e.target.value))
              }
              className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border p-3 text-sm outline-none"
              disabled={!isShopSelected}
            />
          </div>
          <div className="flex-grow">
            <label className="text-sm text-navy-700 dark:text-white">To</label>
            <input
              placeholder="To"
              id={`toInKm-${index}`}
              type="number"
              value={detail?.toInKm ?? ""}
              onChange={(e) =>
                onShippingChange(index, "toInKm", Number(e.target.value))
              }
              className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border p-3 text-sm outline-none"
              disabled={!isShopSelected}
            />
          </div>
          <div className="flex flex-row items-center gap-6">
            <div className="flex-grow">
              <label className="text-sm text-navy-700 dark:text-white">
                Cost
              </label>
              <input
                placeholder="Cost"
                id={`cost-${index}`}
                type="number"
                value={detail?.cost ?? ""}
                onChange={(e) =>
                  onShippingChange(index, "cost", Number(e.target.value))
                }
                className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border p-3 text-sm outline-none"
                disabled={!isShopSelected}
              />
            </div>
            {isShopSelected && (
              <IoCloseCircle
                className="text-black ml-[-1em] mt-6 flex h-12 cursor-pointer rounded-full outline-none"
                onClick={() => onRemoveShippingDetail(index)}
              />
            )}
          </div>
        </div>
      ))}

      <p
        onClick={onAddShippingDetail}
        className="cursor-pointer text-blue-500 underline hover:text-blue-700"
      >
        Add Shipping Detail
      </p>
    </header>
  );
};

export default AddAveragePrice;
