import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createProduct } from "api/product/product";
import { AddFlowerPayload } from "interfaces/product/product";

export const useCreateProduct = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation((payload: AddFlowerPayload) => createProduct(payload), {
    onSuccess: (_, variables, context: { refetch: () => void }) => {
      toast({
        title: "Product was created successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      context?.refetch();
      queryClient.invalidateQueries(["products"]);
    },
    onError: () => {
      toast({
        title: "Product failed to create!",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    },
  });
};
