import { AuthContext } from "contexts/AuthContext";
import { Role } from "interfaces/auth/auth";
import { useContext, useEffect, useState } from "react";
import Loader from "components/loader/Loader";
import { FilteredProductsPayload, SortType } from "interfaces/product/product";
import ReportTable from "./components/ReportTable";

import {
  useGetOrderPeportsAdmin,
  useGetOrderPeportsOwner,
} from "hooks/orders/useGetOrderReportsAdmin";
import AdminReportTable from "./components/AdminReportTable";
import { useGetShopOwners } from "hooks/shop/useGetShopOwner";
import { useGetAllShops } from "hooks/shop/useGetAllShop";

const ReportsView = () => {
  const [user] = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);
  const { data: allFlowerShops } = useGetAllShops();
  const { data: ownerFlowersShops } = useGetShopOwners();
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [sortField, setSortField] = useState<string>("createdAt");
  const [sortType, setSortType] = useState<SortType>(SortType.DESC);
  const itemsPerPage = 10;

  const filters: FilteredProductsPayload = {
    limit: itemsPerPage,
    page: currentPage,
    pageCount: true,
    filter: selectedId
      ? [
          {
            field: "placeId",
            operator: "Equal",
            value: selectedId,
          },
        ]
      : [],
    sort: [
      {
        field: sortField,
        sortType: sortType,
      },
    ],
  };

  const ownerFilters: FilteredProductsPayload = {
    limit: itemsPerPage,
    page: currentPage,
    pageCount: true,
    filter: selectedId
      ? [
          {
            field: "placeId",
            operator: "Equal",
            value: selectedId,
          },
        ]
      : [],
    sort: [
      {
        field: sortField,
        sortType: sortType,
      },
    ],
  };

  const {
    data: orderReports,
    isLoading: orderReportsLoading,
    refetch: refetchOrderReports,
  } = useGetOrderPeportsAdmin(filters);

  const {
    data: orderReportsOwner,
    isLoading: orderReportsOwnerLoading,
    refetch: refetchOrderOwnerReports,
  } = useGetOrderPeportsOwner(ownerFilters);

  useEffect(() => {
    if (user.roles.some((role) => role === Role.ADMIN)) {
      refetchOrderReports();
    } else {
      refetchOrderOwnerReports();
    }
  }, [currentPage, selectedId, sortField, sortType]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  const handleSortChange = (field: string, type: SortType) => {
    setSortField(field);
    setSortType(type);
  };
  const handleShopChange = (id: string | null) => {
    setSelectedId(id);
    setCurrentPage(1);
  };

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="w-ful l mt-3 flex h-fit flex-col gap-5">
        <div className="col-span-4 lg:!mb-0">
          <>
            {user.roles.some((role) => role === Role.ADMIN) && (
              <>
                {orderReports?.results && orderReports?.results.length > 0 ? (
                  <>
                    <AdminReportTable
                      itemsPerPage={itemsPerPage}
                      tableData={orderReports}
                      currentPage={currentPage}
                      sortField={sortField}
                      sortType={sortType}
                      handleSortChange={handleSortChange}
                      handlePageChange={handlePageChange}
                      flowerShops={allFlowerShops || []}
                      selectedId={selectedId}
                      handleShopChange={handleShopChange}
                    />
                  </>
                ) : orderReportsLoading ? (
                  <Loader />
                ) : (
                  <div className="flex flex-col items-center justify-center pt-[15em]">
                    <p>There are no Reports.</p>
                  </div>
                )}
              </>
            )}
            {user.roles.some((role) => role === Role.PLACE_OWNER) && (
              <>
                {orderReportsOwner?.results &&
                orderReportsOwner?.results.length > 0 ? (
                  <>
                    <ReportTable
                      itemsPerPage={itemsPerPage}
                      currentPage={currentPage}
                      sortField={sortField}
                      sortType={sortType}
                      handlePageChange={handlePageChange}
                      handleSortChange={handleSortChange}
                      tableData={orderReportsOwner}
                      flowerShops={ownerFlowersShops || []}
                      selectedId={selectedId}
                      handleShopChange={handleShopChange}
                    />
                  </>
                ) : orderReportsOwnerLoading ? (
                  <Loader />
                ) : (
                  <div className="flex flex-col items-center justify-center pt-[15em]">
                    <p>There are no Reports.</p>
                  </div>
                )}
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default ReportsView;
