import React, { useEffect, useState } from "react";
import Card from "components/card";
import { useAddShipping } from "hooks/shop/useAddShipping";
import { useGetShopOwners } from "hooks/shop/useGetShopOwner";
import { AddAveragePrice } from "../shop/components/AddAveragePrice";
import { ShippingResponse } from "api/shop/shop";
import { useGetAllShippings } from "hooks/shop/useGetShipping";
import { useDeleteShipping } from "hooks/shop/useDeleteShipping";

const defaultShippingDetail: ShippingResponse = {
  _id: null,
  fromInKm: null,
  toInKm: null,
  cost: null,
  flowerShopId: "",
};

const ShippingView = () => {
  const { data } = useGetShopOwners();
  const { mutate: shipping } = useAddShipping();
  const { mutate: deleteShipping } = useDeleteShipping();

  const [shippingDetails, setShippingDetails] = useState<ShippingResponse[]>([
    defaultShippingDetail,
  ]);

  const [selectedShopId, setSelectedShopId] = useState("");
  const [saveDisabled, setSaveDisabled] = useState(true); // State to manage Save button disabled state

  // Fetch shipping details when a shop is selected
  const { data: shippingData } = useGetAllShippings(selectedShopId);

  useEffect(() => {
    if (shippingData && shippingData.length > 0) {
      setShippingDetails(shippingData);
    } else {
      setShippingDetails([defaultShippingDetail]);
    }
  }, [shippingData]);

  const handleShippingChange = (
    index: number,
    field: string,
    value: number
  ) => {
    const newShippingDetails = [...shippingDetails];
    newShippingDetails[index] = {
      ...newShippingDetails[index],
      [field]: value,
    };
    setShippingDetails(newShippingDetails);
    // Check for duplicate values after updating shipping details
    checkRangeOverlap(newShippingDetails);
  };

  const addShippingDetail = () => {
    setShippingDetails([...shippingDetails, defaultShippingDetail]);
  };

  const removeShippingDetail = (index: number) => {
    const detailToRemove = shippingDetails[index];
    if (detailToRemove._id) {
      // Call delete mutation if the shipping detail has an ID
      deleteShipping(detailToRemove._id, {
        onSuccess: () => {
          const updatedShippingDetails = shippingDetails.filter(
            (_, i) => i !== index
          );
          setShippingDetails(updatedShippingDetails);
          checkRangeOverlap(updatedShippingDetails);
        },
      });
    } else {
      const updatedShippingDetails = shippingDetails.filter(
        (_, i) => i !== index
      );
      setShippingDetails(updatedShippingDetails);
      checkRangeOverlap(updatedShippingDetails);
    }
  };

  const handleSave = () => {
    shipping(
      shippingDetails.map((detail) => ({
        ...detail,
        flowerShopId: selectedShopId,
      }))
    );
  };

  const checkRangeOverlap = (details: ShippingResponse[]) => {
    let hasOverlap = false;

    for (let i = 0; i < details.length; i++) {
      for (let j = i + 1; j < details.length; j++) {
        const range1 = [details[i].fromInKm, details[i].toInKm];
        const range2 = [details[j].fromInKm, details[j].toInKm];

        // Check if ranges overlap
        if (
          range1[0] !== null &&
          range1[1] !== null &&
          range2[0] !== null &&
          range2[1] !== null &&
          ((range1[0] >= range2[0] && range1[0] <= range2[1]) ||
            (range1[1] >= range2[0] && range1[1] <= range2[1]) ||
            (range2[0] >= range1[0] && range2[0] <= range1[1]) ||
            (range2[1] >= range1[0] && range2[1] <= range1[1]))
        ) {
          hasOverlap = true;
          break;
        }
      }
      if (hasOverlap) break;
    }

    setSaveDisabled(hasOverlap);
  };

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="mt-3 flex h-fit w-full flex-col gap-5">
        <Card extra="p-6 w-full">
          <div className="grid w-full grid-cols-1 gap-4 sm:grid-cols-2">
            <AddAveragePrice
              data={data}
              shippingDetails={shippingDetails}
              onShippingChange={handleShippingChange}
              onAddShippingDetail={addShippingDetail}
              onRemoveShippingDetail={removeShippingDetail}
              selectedShopId={selectedShopId}
              onShopChange={setSelectedShopId}
            />
          </div>
          <div className="mt-4 flex justify-end">
            <button
              className={`font-small w-[12em] rounded-xl bg-[#71cb90] p-4 py-[8px] text-sm text-white ${
                saveDisabled ? "cursor-not-allowed bg-gray-300" : ""
              }`}
              onClick={handleSave}
              disabled={saveDisabled}
            >
              Save
            </button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ShippingView;
