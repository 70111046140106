import { axiosInstance } from "api/config";
import { BaseUrl } from "enums/BaseUrl";

export interface AddConfigs {
  key: string;
  value: number;
}

export interface Configs {
  _id: string;
  key: string;
  value: number;
}

export interface Comission {
  _id: string;
  from: number;
  fee: number;
  to: number;
}

export const addConfig = async (payload: AddConfigs) => {
  const { data } = await axiosInstance.post(
    `${BaseUrl.DEVELOPMENT}/configs/add`,
    payload
  );

  return data;
};

export const getConfigs = async () => {
  const { data } = await axiosInstance.get<Configs[]>(
    `${BaseUrl.DEVELOPMENT}/configs/get-configs-admin`
  );

  return data;
};

export interface CommisionPayload {
  from: number;
  to: number;
  fee: number;
  _id: string;
}

export interface AddCommision {
  from: number;
  to: number;
  fee: number;
}

export const addCommision = async (payload: AddCommision[]) => {
  const { data } = await axiosInstance.post<Comission[]>(
    `${BaseUrl.DEVELOPMENT}/comission/add`,
    payload
  );

  return data;
};

export const deleteCommision = async (id: string) => {
  const { data } = await axiosInstance.delete<any>(
    `${BaseUrl.DEVELOPMENT}/comission/${id}`
  );

  return data;
};

export const getCommisions = async () => {
  const { data } = await axiosInstance.get<Comission[]>(
    `${BaseUrl.DEVELOPMENT}/comission/getAll`
  );

  return data;
};
