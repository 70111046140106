import { axiosInstance } from "api/config";
import { BaseUrl } from "enums/BaseUrl";
import { FilteredProductsPayload } from "interfaces/product/product";

interface LineItem {
  _id: string;
  productId: {
    name: string;
  };
  productType: string;
  quantity: number;
  basePrice: number;
  variant: string | null;
  taxPercentage: number;
  markupPercentage: number;
  wholesalePrice: number;
  totalPrice: number;
  createdAt: string;
  updatedAt: string;
  __v: number;
  basePricePerUnit?: number; // Optional property
  wholesalePricePerUnit?: number; // Optional property
  markupAmount?: number; // Optional property
  markupAmountPerUnit?: number; // Optional property
}

interface MediaItem {
  _id: string;
  mediaId: string;
  markupPercentage: number;
  type: string;
  basePricePerUnit: number;
  markupAmount: number;
  wholeSalePrice: number;
  markupAmountPerUnit: number;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface Order {
  _id: string;
  createdBy: string;
  placeId: {
    name: string;
    state: string;
    city: string;
    zip: string;
    desc: string;
    userId: string;
  };
  deliveryDate: string;
  lineItems: LineItem[];
  mediaItems: MediaItem[];
  baseLineItemsPriceAmount: number;
  basePriceAmount: number;
  wholeSaleOrderAmount: number;
  totalMarkupAmount: number;
  orderAmount: number;
  deliveryAmount: number;
  deliveryAmountWithMarkup: number;
  totalDeliveryAmount: number;
  taxPercentage: number;
  taxAmount: number;
  baseOrderAmount: number;
  totalAmount: number;
  status: string;
  giftMessage: string;
  receiverPhoneNumber: string;
  street: string;
  state: string;
  city: string;
  zip: string;
  orderCode: number;
  orderReferenceNumber: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface OrderResponse {
  results: Order[];
  totalPages: number;
}

export const getFlilteredOwnerOrders = async (
  payload?: FilteredProductsPayload
) => {
  const { data } = await axiosInstance.post<OrderResponse>(
    `${BaseUrl.DEVELOPMENT}/orders/allOrderOfMyShop`,
    payload
  );

  return data;
};

export const getFlilteredOrders = async (payload?: FilteredProductsPayload) => {
  const { data } = await axiosInstance.post<OrderResponse>(
    `${BaseUrl.DEVELOPMENT}/orders/allorders/filter`,
    payload
  );

  return data;
};

export const getFlilteredOrdersById = async (
  orderId: string,
  payload?: FilteredProductsPayload
) => {
  const { data } = await axiosInstance.post<any>(
    `${BaseUrl.DEVELOPMENT}/orders/order-of-my-shop/${orderId}`,
    payload
  );

  return data;
};

export const getOrdersById = async (orderId: string) => {
  const { data } = await axiosInstance.get<any>(
    `${BaseUrl.DEVELOPMENT}/orders/${orderId}`
  );

  return data;
};

interface StatusPayload {
  orderId: string;
  status: string;
}

export const changeStatus = async (order: StatusPayload) => {
  const { data } = await axiosInstance.patch(
    `${BaseUrl.DEVELOPMENT}/orders/set-order-status-of-my-shop/${order.orderId}`,
    { status: order.status }
  );

  return data;
};
