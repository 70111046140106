import { axiosInstance } from "api/config";
import { BaseUrl } from "enums/BaseUrl";
import {
  AddFloweObject,
  AddFlowerPayload,
  DashboardProductResponse,
  FilteredProductsPayload,
  FlowerType,
  ProductResponse,
} from "interfaces/product/product";

export interface FlowerResult {
  results: FlowerProduct[];
  totalPages: number;
}

export interface FlowerProduct {
  _id: string;
  name: string;
  desc: string;
  file: FileInfo[];
  flowershopDetails: FlowerShopDetails[];
  flowerShopId: string;
  currentPrice: number;
  discount: number | null;
  flowerTypeId: {
    _id: string;
    name: string;
    __v: number;
  };
  isAddOn: boolean;
  pricingOptionId: string | null;
  selectedOptions: OptionInfo[];
  sizesWithPrices: SizeWithPrice[];
  __v: number;
  price: number | null;
}

export interface FlowerShopDetails {
  name: string;
}

interface FileInfo {
  _id: string;
  createdBy: string;
  type: string;
  path: string;
  fullPath: string;
  contentType: string;
  createdAt: string;
  updatedAt: string;
  thumbnails?: any;
}

interface OptionInfo {
  _id: string;
  name: string;
  desc: string;
  __v: number;
}

interface SizeWithPrice {
  size: string;
  price: number;
  _id: string;
}

// to set exact interface
export const getFlowers = async () => {
  const { data } = await axiosInstance.get<any>(
    `${BaseUrl.DEVELOPMENT}/flowers/get-all-fllowers`
  );

  return data;
};

export const getFlowerType = async () => {
  const { data } = await axiosInstance.get<FlowerType[]>(
    `${BaseUrl.DEVELOPMENT}/flowers/flower-type`
  );

  return data;
};

export const getFlilteredProducts = async (
  payload?: FilteredProductsPayload
) => {
  const { data } = await axiosInstance.post<FlowerResult>(
    `${BaseUrl.DEVELOPMENT}/flowers/filter`,
    payload
  );

  return data;
};

export const getFlilteredOwnerProducts = async (
  payload?: FilteredProductsPayload
) => {
  const { data } = await axiosInstance.post<FlowerResult>(
    `${BaseUrl.DEVELOPMENT}/flowers/allFlowersOfMyShop`,
    payload
  );

  return data;
};

// to set exact interface
export const getFlowersById = async (id: string) => {
  const { data } = await axiosInstance.get<ProductResponse>(
    `${BaseUrl.DEVELOPMENT}/flowers/get-flower-by-id?id=${id}`
  );

  return data;
};

export const getFlowersByIdDashboard = async (id: string) => {
  const { data } = await axiosInstance.get<DashboardProductResponse>(
    `${BaseUrl.DEVELOPMENT}/flowers/get-flower-by-id-dashboard?id=${id}`
  );

  return data;
};

export const createProduct = async (payload: AddFlowerPayload) => {
  const { data } = await axiosInstance.post<ProductResponse>(
    `${BaseUrl.DEVELOPMENT}/flowers/add`,
    payload
  );

  return data;
};

export const updateProduct = async (payload: AddFlowerPayload) => {
  const { data } = await axiosInstance.put<ProductResponse>(
    `${BaseUrl.DEVELOPMENT}/flowers/update-flower/${payload._id}`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};

export const uploadProductPhotos = async (formData: FormData) => {
  const { data } = await axiosInstance.post(
    `${BaseUrl.DEVELOPMENT}/flowers/upload-media`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return data;
};

export const updateFlower = async (payload: AddFloweObject) => {
  const { data } = await axiosInstance.put(
    `${BaseUrl.DEVELOPMENT}/flowers/update-flower/${payload.id}`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};
