import {
  Box,
  Checkbox,
  FormLabel,
  Grid,
  GridItem,
  Select,
  Spinner,
  Tab,
  TabList,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import Card from "components/card";
import InputField from "components/fields/InputField";
import { FormikProvider, useFormik } from "formik";
import { useCreateProduct } from "hooks/products/useCreateProduct";
import { useNavigate } from "react-router-dom";
import { IoIosAdd } from "react-icons/io";
import { useCallback, useEffect, useState } from "react";
import { useGetShopOwners } from "hooks/shop/useGetShopOwner";
import { useGetGlobals } from "hooks/globals/useGetGlobals";
import { useGetFlowerType } from "hooks/products/useGetFlowerType";
import { useUploadProductPhotos } from "hooks/products/useUploadProductPhotos";
import { useDropzone } from "react-dropzone";
import { LuUpload } from "react-icons/lu";
import { FaTrash } from "react-icons/fa";
import { DashboardProductResponse } from "interfaces/product/product";
import { useUpdateProduct } from "hooks/products/useUpdateProduct";

interface UploadedImage {
  preview: string;
  file: File;
}

interface EditProductProps {
  product: DashboardProductResponse;
}

export const EditProduct: React.FC<EditProductProps> = ({ product }) => {
  const navigate = useNavigate();
  const { mutate: updateProduct, isLoading } = useUpdateProduct();
  const { data: flowerType } = useGetFlowerType();
  const { mutate: uploadPhoto } = useUploadProductPhotos();
  const { data: globals } = useGetGlobals();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [selectedChoices, setSelectedChoices] = useState<
    { settingsId: string; selectedOptions: string[] }[]
  >([]);
  const { data } = useGetShopOwners();
  const [, setSelectedType] = useState(null);
  const [, setUploadedImageUrls] = useState<string[]>([]);
  const [uploadedImages, setUploadedImages] = useState<UploadedImage[]>([]);
  const [, setOriginalSizesWithPrices] = useState([
    {
      size: "",
      originalPrice: null,
    },
  ]);

  const addOns = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const addSizeAndPrice = () => {
    setOriginalSizesWithPrices([
      ...formik.values.originalSizesWithPrices,
      { size: "", originalPrice: null },
    ]);
    formik.setFieldValue("originalSizesWithPrices", [
      ...formik.values.originalSizesWithPrices,
      { size: "", originalPrice: null },
    ]);
  };

  useEffect(() => {
    if (
      product &&
      product.originalSizesWithPrices &&
      product.originalSizesWithPrices.length > 0
    ) {
      setOriginalSizesWithPrices(product.originalSizesWithPrices);
    }
  }, [product]);

  useEffect(() => {
    const initialChoices = product.selectedOptions
      .map((option) => {
        const globalSetting = globals?.find((global) =>
          global.settingsItems.some((item) => item._id === option._id)
        );
        return globalSetting
          ? { settingsId: globalSetting._id, selectedOptions: [option._id] }
          : null;
      })
      .filter((choice) => choice !== null);
    setSelectedChoices(initialChoices);
  }, [product.selectedOptions, globals]);

  const handleCheckboxChange = (settingsId: string, optionId: string) => {
    setSelectedChoices((prevChoices) => {
      const choiceIndex = prevChoices.findIndex(
        (choice) => choice.settingsId === settingsId
      );
      if (choiceIndex >= 0) {
        const updatedChoices = [...prevChoices];
        const optionIndex =
          updatedChoices[choiceIndex].selectedOptions.indexOf(optionId);
        if (optionIndex >= 0) {
          updatedChoices[choiceIndex].selectedOptions.splice(optionIndex, 1);
          if (updatedChoices[choiceIndex].selectedOptions.length === 0) {
            updatedChoices.splice(choiceIndex, 1);
          }
        } else {
          updatedChoices[choiceIndex].selectedOptions.push(optionId);
        }
        return updatedChoices;
      } else {
        return [...prevChoices, { settingsId, selectedOptions: [optionId] }];
      }
    });
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setUploadedImages((prevImages) => [...prevImages, ...newFiles]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleUpload = async (flowerId: string, file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("flowerId", flowerId);

    try {
      uploadPhoto(formData, {
        onSuccess: (data) => {
          setUploadedImageUrls((prevUrls) => [...prevUrls, data.url || data]);
        },
        onError: (error) => {
          console.error("Error uploading file:", error);
        },
      });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleDeleteImage = (preview: string) => {
    setUploadedImages((prevImages) =>
      prevImages.filter((image) => image.preview !== preview)
    );
  };

  const formik = useFormik({
    initialValues: {
      _id: product._id,
      name: product.name || "",
      desc: product.desc || "",
      flowerShopId: product.flowerShopId || "",
      currentPrice: product.currentPrice || null,
      originalPrice: product.originalPrice || null,
      discount: product.discount || null,
      flowerTypeId: product.flowerTypeId || "",
      isAddOn: product.isAddOn || false,
      originalSizesWithPrices:
        product.originalSizesWithPrices.length > 0
          ? product.originalSizesWithPrices
          : [
              {
                size: "",
                originalPrice: null,
              },
            ],
       selectedOptions: product.selectedOptions || [],
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const updatedValues = {
          ...values,
          currentPrice: values.originalPrice,
           settings: selectedChoices,
           sizesWithPrices:values.originalSizesWithPrices.map(item => ({
            size: item.size,
            price: item.originalPrice
        }))
        };
        updateProduct(updatedValues, {
          onSuccess: async (updateProduct) => {
            if (uploadedImages.length > 0) {
              for (const image of uploadedImages) {
                await handleUpload(updateProduct._id, image.file);
              }
            }
            navigate("/admin/products");
            formik.resetForm();
          },
          onError: () => {
            formik.resetForm();
          },
        });
      } catch (error) {
        console.error("Error creating product:", error);
        formik.resetForm();
      }
    },
  });

  const isFormInvalid =
    formik.isValid &&
    formik.values.flowerShopId &&
    formik.values.desc &&
    formik.values.name &&
    formik.values.flowerTypeId;

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className="pb-6">
            <Tabs
              variant="unstyled"
              index={activeTab}
              onChange={(index) => setActiveTab(index)}
            >
              <TabList>
                {globals?.map((item, index) => (
                  <Tab
                    key={index}
                    bg={"white"}
                    borderTopRightRadius={"10px"}
                    borderTopLeftRadius={"10px"}
                    _selected={{
                      color: "white",
                      bg: "#71cb90",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  >
                    {item.name}
                  </Tab>
                ))}
              </TabList>
              <TabPanels>
                {globals?.map((global, index) => (
                  <div
                    key={global._id}
                    className={`flex flex-col rounded-[10px] rounded-t-none bg-white bg-clip-border shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none`}
                  >
                    {activeTab === index && (
                      <Grid
                        className="p-8"
                        templateColumns="repeat(4, 1fr)"
                        gap={4}
                      >
                        {global?.settingsItems?.map((settingItem) => (
                          <GridItem key={settingItem._id}>
                            <Box gap={4} display="flex" alignItems="center">
                              <Checkbox
                                borderRadius={12}
                                colorScheme="green"
                                isChecked={selectedChoices.some(
                                  (choice) =>
                                    choice.settingsId === global._id &&
                                    choice.selectedOptions.includes(
                                      settingItem._id
                                    )
                                )}
                                size="lg"
                                onChange={() =>
                                  handleCheckboxChange(
                                    global._id,
                                    settingItem._id
                                  )
                                }
                              />
                              <p>{settingItem.name}</p>
                            </Box>
                          </GridItem>
                        ))}
                      </Grid>
                    )}
                  </div>
                ))}
              </TabPanels>
            </Tabs>
          </div>
          <Card extra={"w-full h-full sm:overflow-auto px-6 mb-4"}>
            <h1 className=" pb-2 pt-6 text-xl font-bold text-navy-700 dark:text-white">
              Product Details
            </h1>
            <p className="pb-4 text-[13px] text-gray-500">
              Add Product information (Name, Description , Discount price,
              Current price and product type)
            </p>
            <div className="flex flex-col  lg:flex-row">
              <div className="w-full lg:w-[50%]">
                <div className="lg:w-[60%]">
                  <div className="flex  flex-col justify-between  lg:flex-col">
                    <FormLabel
                      style={{
                        fontSize: "14px",
                        paddingLeft: "0.3em",
                      }}
                    >
                      Select Flower Shop
                    </FormLabel>
                    <Select
                      sx={{
                        borderRadius: "0.75rem",
                        height: "3.5em",
                        fontSize: "14px",
                        "@media screen and (max-width: 940px)": {
                          width: "22.5em",
                        },
                      }}
                      className="mb-3 flex items-center justify-center border bg-white/0 outline-none"
                      {...formik.getFieldProps("flowerShopId")}
                      variant="auth"
                      placeholder="Select Flower Shop"
                      id="flowerShopId"
                    >
                      {Array.isArray(data) &&
                        data?.map((product) => (
                          <option key={product._id} value={product._id}>
                            {product.name}
                          </option>
                        ))}
                    </Select>
                  </div>
                </div>
                <div className="w-full">
                  <div className="flex w-full flex-col justify-between gap-4 lg:flex-row">
                    <InputField
                      {...formik.getFieldProps("name")}
                      variant="auth"
                      extra="mb-3 lg:w-[60%]"
                      label="Product Name*"
                      placeholder="Enter your Product Name"
                      id="name"
                      type="text"
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="w-full">
                  <div className="flex w-full flex-col justify-between gap-4 lg:flex-row">
                    <InputField
                      {...formik.getFieldProps("desc")}
                      variant="auth"
                      extra="mb-3 lg:w-[60%]"
                      label="Product Description*"
                      placeholder="Enter your Product Description"
                      id="desc"
                      type="text"
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="flex w-full flex-col  gap-2 lg:flex-row">
                  <InputField
                    {...formik.getFieldProps("originalPrice")}
                    variant="auth"
                    extra="mb-3 lg:w-[100%]"
                    label="Product Current Price"
                    placeholder="Enter Current Price"
                    id="originalPrice"
                    type="text"
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="flex w-full flex-col  gap-2 lg:flex-row">
                  <div className="flex  flex-col justify-between lg:flex-col">
                    <FormLabel
                      style={{
                        fontSize: "14px",
                        paddingLeft: "0.3em",
                      }}
                    >
                      Type*
                    </FormLabel>
                    <Select
                      sx={{
                        borderRadius: "0.75rem",
                        height: "3.5em",
                        fontSize: "14px",
                        width: "16em",
                        "@media screen and (max-width: 940px)": {
                          width: "22.5em",
                        },
                      }}
                      className="mb-3 flex items-center  justify-center border  bg-white/0 outline-none  lg:w-[80%]"
                      {...formik.getFieldProps("flowerTypeId")}
                      variant="auth"
                      placeholder="Enter Type"
                      id="flowerTypeId"
                    >
                      {flowerType?.map((type) => (
                        <>
                          <option key={type._id} value={type._id}>
                            {type.name}
                          </option>
                        </>
                      ))}
                    </Select>
                  </div>
                  <div className="flex  flex-col justify-between lg:flex-col">
                    <FormLabel
                      style={{
                        fontSize: "14px",
                        paddingLeft: "0.3em",
                      }}
                    >
                      Is Add On?
                    </FormLabel>
                    <Select
                      sx={{
                        borderRadius: "0.75rem",
                        height: "3.5em",
                        fontSize: "14px",
                        width: "16em",
                        "@media screen and (max-width: 940px)": {
                          width: "22.5em",
                        },
                      }}
                      className="mb-3 flex items-center justify-center border bg-white/0 outline-none"
                      {...formik.getFieldProps("isAddOn")}
                      variant="auth"
                      placeholder="Yes or No"
                      id="isAddOn"
                      // value={selectedType}
                      onChange={(e) => {
                        const selectedValue = e.target.value === "true";
                        setSelectedType(selectedValue);
                        formik.setFieldValue("isAddOn", selectedValue);
                      }}
                    >
                      {addOns?.map(({ label, value }) => (
                        <option key={String(value)} value={value.toString()}>
                          {label}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div>
                  {formik.values.originalSizesWithPrices.map((item, index) => (
                    <div
                      key={index}
                      className="flex w-full flex-col gap-2 lg:flex-row"
                    >
                      <InputField
                        {...formik.getFieldProps(
                          `originalSizesWithPrices[${index}].size`
                        )}
                        variant="auth"
                        extra="mb-3 lg:w-[80%]"
                        label={`Size ${index + 1}`}
                        placeholder="Enter size (XL, L, M)"
                        id={`size_${index}`}
                        type="text"
                        onChange={formik.handleChange}
                      />
                      <InputField
                        {...formik.getFieldProps(
                          `originalSizesWithPrices[${index}].originalPrice`
                        )}
                        variant="auth"
                        extra="mb-3 lg:w-[80%]"
                        label={`Price ${index + 1}`}
                        placeholder="Enter price"
                        id={`originalPrice_${index}`}
                        type="text"
                        onChange={formik.handleChange}
                      />
                    </div>
                  ))}

                  <div
                    onClick={addSizeAndPrice}
                    className="mb-6 flex cursor-pointer justify-start lg:justify-end"
                  >
                    <div
                      onClick={addSizeAndPrice}
                      className="font-small align-center mt-4 flex w-[5em] justify-center rounded-xl bg-[#71cb90] py-[8px] text-sm text-white "
                    >
                      Add
                      <IoIosAdd size={22} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <Card extra="w-full h-full sm:overflow-auto px-6 mb-4 cursor-pointer">
            <h1 className="pt-6 text-xl font-bold text-navy-700 dark:text-white">
              Product Images
            </h1>
            <header className="relative flex cursor-pointer flex-row items-center justify-between pt-4">
              <div className="w-full pb-6 pt-6">
                {(uploadedImages.length > 0 ||
                  (product?.file && product?.file.length > 0)) && (
                  <div
                    className={`grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5`}
                  >
                    {/* Dropzone */}
                    <div
                      {...getRootProps()}
                      className={`mb-4 flex h-[12em] w-full flex-col items-center justify-center border-[1.5px] border-dashed bg-white/0 p-4 text-center ${
                        uploadedImages.length > 0 ||
                        (product?.file && product?.file.length > 0)
                          ? "sm:w-[12.5em]"
                          : ""
                      }`}
                    >
                      <input {...getInputProps()} />
                      <>
                        <LuUpload
                          style={{ fontSize: "2em", color: "#979797" }}
                        />
                        <p className="text-[16px] font-medium text-[#979797]">
                          Upload Files
                        </p>
                        <p className="text-[12px] text-[#979797]">
                          PNG and JPG are allowed
                        </p>
                      </>
                    </div>
                    {product?.file &&
                      product?.file.map((image) => (
                        <div key={image?.id} className="group relative">
                          <img
                            src={image?.path || ""}
                            alt="Existing"
                            className="mx-2 max-h-[12em] w-[12em]"
                          />
                          <FaTrash className="text-black absolute right-0 top-1 cursor-pointer group-hover:text-red-900" />
                        </div>
                      ))}
                    {uploadedImages.map((image) => (
                      <div key={image.preview} className="group relative">
                        <img
                          src={image.preview}
                          alt="Uploaded"
                          className="mx-2 max-h-[12em] w-[12em]"
                        />
                        <FaTrash
                          className="text-black absolute right-0 top-1 cursor-pointer group-hover:text-red-900"
                          onClick={() => handleDeleteImage(image.preview)}
                        />
                      </div>
                    ))}
                  </div>
                )}
                {uploadedImages.length === 0 &&
                  (!product?.file || product.file.length === 0) && (
                    <div
                      {...getRootProps()}
                      className={`mb-4 flex h-[12em] w-full flex-col items-center justify-center border-[1.5px] border-dashed bg-white/0 p-4 text-center`}
                    >
                      <input {...getInputProps()} />
                      <>
                        <LuUpload
                          style={{ fontSize: "2em", color: "#979797" }}
                        />
                        <p className="text-[16px] font-medium text-[#979797]">
                          Upload Files
                        </p>
                        <p className="text-[12px] text-[#979797]">
                          PNG and JPG are allowed
                        </p>
                      </>
                    </div>
                  )}
              </div>
            </header>
          </Card>
          <div className="flex justify-end pb-6">
            <button
              className={`mt-2 w-[5em] rounded-xl py-[12px] text-base font-medium text-white transition duration-200 
            ${
              !isFormInvalid
                ? "cursor-not-allowed bg-gray-400"
                : "bg-[#71cb90] hover:bg-[#71cb90] active:bg-[#71cb90]"
            }
            dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
              disabled={!isFormInvalid}
            >
              {isLoading ? <Spinner /> : "Save"}
            </button>
          </div>
        </form>
      </FormikProvider>
    </>
  );
};
