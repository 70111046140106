import Card from "components/card";
import { Globals } from "interfaces/globals/globals";
import { useState } from "react";
import AddCategorySettingModal from "./AddCategorySetting";
import AddItemModal from "./AddItemModal";

interface Props {
  data: Globals[];
}

const Settings: React.FC<Props> = ({ data }) => {
  const [expandedMenuIndex, setExpandedMenuIndex] = useState<number[]>([]);

  // Initialize expandedMenuIndex with indices of all menus
  useState(() => {
    const allIndices = data?.map((_, index) => index);
    setExpandedMenuIndex(allIndices);
  });

  const toggleMenu = (index: number) => {
    setExpandedMenuIndex((prevIndices) =>
      prevIndices.includes(index)
        ? prevIndices.filter((i) => i !== index)
        : [...prevIndices, index]
    );
  };
  return (
    <>
      <div className="flex justify-end pb-4">
        <AddCategorySettingModal />
      </div>
      {data?.map((menu, index) => (
        <div className="mb-4" key={menu._id}>
          <Card extra={`w-full h-full sm:overflow-auto px-6 cursor-pointer `}>
            <header className="relative flex cursor-pointer flex-row items-center justify-between pb-4 pt-4">
              <div className="w-full">
                <h1 onClick={() => toggleMenu(index)} className="font-bold">
                  {menu.name}
                </h1>
              </div>
              <AddItemModal _id={menu._id} />
            </header>
          </Card>
          <div
            className={`mb-6 grid grid-cols-2 gap-5 rounded-b-lg bg-[#FFFFFF] pl-8 md:grid-cols-7 ${
              expandedMenuIndex.includes(index) ? "block" : "hidden"
            }`}
          >
            {menu?.settingsItems?.map((item, index) => (
              <div className="flex w-full flex-col pb-4 pr-6 pt-4" key={index}>
                <div className="flex w-full flex-col gap-4">
                  <p className="text-[14px]">{item.name}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default Settings;
