import React, { useState } from "react";
import BarChart from "components/charts/BarChart";
import { barChartOptionsDailyTraffic } from "variables/charts";
import Card from "components/card";
import { TotalSalesType } from "api/reports/reports";
import Loader from "components/loader/Loader";

interface Props {
  salesData: TotalSalesType[];
  onPeriodChange: (period: string) => void;
  isLoading: boolean;
}

const TotalSales: React.FC<Props> = ({
  salesData,
  onPeriodChange,
  isLoading,
}) => {
  const [selectedPeriod, setSelectedPeriod] = useState("day");

  const barChartData = salesData
    ? [
        {
          name: "Total Sales",
          data: salesData.map((item) => item.totalSales),
        },
      ]
    : [];

  const handlePeriodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const period = event.target.value;
    setSelectedPeriod(period);
    onPeriodChange(period);
  };

  const categories = salesData
    ? salesData.map((item) => {
        if (selectedPeriod === "day") {
          return `${item.date}`;
        } else if (selectedPeriod === "month") {
          return `${item.date}`;
        } else if (selectedPeriod === "year") {
          return `${item.date}`;
        } else {
          return "";
        }
      })
    : [];

  const updatedBarChartOptions = {
    ...barChartOptionsDailyTraffic,
    xaxis: {
      ...barChartOptionsDailyTraffic.xaxis,
      categories: categories,
    },
  };

  return (
    <Card extra="pb-7 p-[20px]">
      <div className="flex flex-row justify-between px-3 pt-2">
        <div>
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">
            Total Sales
          </h4>
        </div>
        <div className="mb-6 flex items-center justify-center">
          <select
            defaultValue="day"
            onChange={handlePeriodChange}
            className="mb-3 mr-2 flex items-center justify-center text-sm font-bold text-gray-600 hover:cursor-pointer dark:!bg-navy-800 dark:text-white"
          >
            <option value="day">Weekly</option>
            <option value="month">Monthly</option>
            <option value="year">Yearly</option>
          </select>
        </div>
      </div>
      <div className="h-[300px] w-full pb-0 pt-10">
        {isLoading ? (
          <Loader />
        ) : (
          <BarChart
            chartData={barChartData}
            chartOptions={updatedBarChartOptions}
          />
        )}
      </div>
    </Card>
  );
};

export default TotalSales;
