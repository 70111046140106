import { useQuery } from "@tanstack/react-query";
import {
  getOrderReportsAdmin,
  getOrderReportsOwner,
} from "api/reports/reports";
import { FilteredProductsPayload } from "interfaces/product/product";

export const useGetOrderPeportsAdmin = (payload?: FilteredProductsPayload) => {
  return useQuery(["orderReportsAdmin"], () => getOrderReportsAdmin(payload));
};

export const useGetOrderPeportsOwner = (payload?: FilteredProductsPayload) => {
  return useQuery(["orderReportsOwner"], () => getOrderReportsOwner(payload));
};
