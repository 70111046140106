import { useNavigate } from "react-router-dom";
import { LoginForm } from "./components/LoginForm";

export default function SignIn() {
  const navigate = useNavigate();

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your email and password to sign in!
        </p>
        <LoginForm />
        <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <a
            onClick={() => navigate("/auth/sign-up")}
            href=" "
            className="ml-1 text-sm font-medium text-[#71cb90] hover:text-[#71cb90] dark:text-white"
          >
            Create an account
          </a>
        </div>
      </div>
    </div>
  );
}
