import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { changeReportStatus } from "api/reports/reports";

interface StatusPayload {
  id: string;
  status: string;
}

export const useChangeReportStatus = () => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation((order: StatusPayload) => changeReportStatus(order), {
    onSuccess: (_, variables, context: { refetch: () => void }) => {
      toast({
        title: "Report Status was changed successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      context?.refetch();
      queryClient.invalidateQueries(["orderReportsAdmin"]);
    },
    onError: () => {
      toast({
        title: "Report status failed to be changed!",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    },
  });
};
