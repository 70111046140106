import { useNavigate } from "react-router-dom";
import { SignUpForm } from "./components/SignUpForm";

export default function SignUpLayout() {
  const navigate = useNavigate();

  return (
    <div className="flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-[3vh] w-full max-w-full flex-col items-center md:pl-4  lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign Up
        </h4>
        <p className="mb-1 ml-1 text-base text-gray-600">
          Fill the informations to Sign Up!
        </p>
        <SignUpForm />
        <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Already registered?
          </span>
          <span
            onClick={() => navigate("/auth/login")}
            className="ml-1 cursor-pointer text-sm font-medium text-[#71cb90] hover:text-[#71cb90] dark:text-white"
          >
            Log In
          </span>
        </div>
      </div>
    </div>
  );
}
