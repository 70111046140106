import { createContext } from "react";
import { AuthResponse, LoginResponse } from "interfaces/auth/auth";

export const defaultAuthResponse: LoginResponse = {
  address: "",
  first_name: "",
  gender: "",
  last_name: "",
  phone_number: "",
  email: "",
  id: "",
  roles: [],
  username: "",
  _id: "",
};

export const AuthContext = createContext<
  // eslint-disable-next-line no-unused-vars
  [LoginResponse, (authRepsone: LoginResponse) => void]
>([defaultAuthResponse, () => null]);

export const defaultTokenResponse: AuthResponse = {
  AuthenticationResult: {
    AccessToken: "",
    IdToken: "",
    RefreshToken: "",
  },
};
export const TokenContext = createContext({
  token: defaultTokenResponse, // Your initial user state
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setToken: (token: AuthResponse) => {}, // Function to update the user
});
