export interface LoginResponse {
  _id: string;
  address: string;
  email: string;
  first_name: string;
  phone_number: string;
  gender: string;
  id: string;
  last_name: string;
  roles: Role[];
  username: string;
}

export interface AuthResponse {
  AuthenticationResult: AuthenticationResult;
}

export interface AuthenticationResult {
  AccessToken: string;
  IdToken: string;
  RefreshToken: string;
}
export interface RefreshTokenResponse {
  accessToken: string;
  idToken: string;
  message: string;
}
export interface RefreshTokenPayload {
  refresh_token: string;
}

export interface LoginPayload {
  email: string;
  password: string;
}

export interface ForgotPasswordPayload {
  email: string;
}

export interface EmailPayload {
  code: string;
}

export interface ResetPasswordObject {
  code: string;
  payload: ResetPasswordPayload;
}

export interface ResetPasswordPayload {
  password: string;
  confirmPassword: string;
}

export interface UserUpdateObject {
  payload: UserUpdatePayload;
}

export interface UserUpdatePayload {
  first_name: string;
  last_name: string;
  phone_number: string;
  gender: string;
  accessToken: string;
}

export interface PasswordUpdateObject {
  payload: PasswordUpdatePayload;
}

export interface PasswordUpdatePayload {
  previousPassword: string;
  proposedPassword: string;
  accessToken: string;
}

export interface SignUpPayload {
  password: string;
  email: string;
  first_name: string;
  phone_number: string;
  last_name: string;
  birthdate: string;
  gender: string;
  address: string;
  roles: Role[];
}

export enum Role {
  ADMIN = "ADMIN",
  CLIENT = "CLIENT",
  PLACE_OWNER = "PLACE_OWNER",
  STAFF = "STAFF",
}
