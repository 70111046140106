import { FormLabel, Spinner, useToast } from "@chakra-ui/react";
import Card from "components/card";
import InputField from "components/fields/InputField";
import { FormikProvider, useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import { LuUpload } from "react-icons/lu";
import { ShopResponse, UploadLogo } from "interfaces/shop/shop";
import { useUpdateShopOwner } from "hooks/shop/updateShopOwner";
import { useUploadLogo } from "hooks/shop/useUploadLogo";
import GoogleMap from "components/map/Map";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  street: Yup.string().required("Required"),
  zip: Yup.string().required("Required"),
  phoneNumber: Yup.string().required("Required"),
});

interface EditBasicInfoProps {
  shop: ShopResponse;
}

export const AdminEditBasicInfo: React.FC<EditBasicInfoProps> = ({ shop }) => {
  const { id } = useParams();
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string | null>(null);
  const { mutate, isLoading } = useUpdateShopOwner(String(id));
  const uploadLogoMutation = useUploadLogo();
  const navigate = useNavigate();
  const [coordinates, setCoordinates] = useState({
    latitude: 42.6629,
    longitude: 21.1655,
  });

  const handleApprove = () => {
    mutate({ _id: shop._id, isVerifiedStatus: "APPROVED" });
    navigate(`/admin/shop`);
  };

  const handleDecline = () => {
    mutate({ _id: shop._id, isVerifiedStatus: "DECLINED" });
    navigate(`/admin/shop`);
  };

  const handleUploadLogo = async (file: File | UploadLogo, _id: string) => {
    try {
      // Convert the uploaded file to a data URL for preview
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedImageUrl(reader.result as string);
      };
      if (file instanceof File) {
        reader.readAsDataURL(file);
      }

      // Create FormData and append file and other fields
      const formData = new FormData();
      formData.append("file", file instanceof File ? file : file.data);

      formData.append("flowerShopId", _id);

      // Call the mutate function with formData
      const response = await uploadLogoMutation.mutate(formData);

      // Handle the response as needed
      console.log("Logo uploaded successfully:", response);
    } catch (error) {
      console.error("Error uploading logo:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      _id: shop._id,
      name: shop.name || "",
      email: shop.email || "",
      website: shop.website || "",
      avgPrice: {
        from: shop.avgPrice?.from || 0,
        to: shop.avgPrice?.to || 0,
      },
      logo:
        shop.logo instanceof File
          ? null
          : {
              path: (shop.logo && shop.logo.path) || "",
            },
      aboutUs: shop.aboutUs || "",
      phoneNumber: shop.phoneNumber || "",
      openingHours: [],
      street: shop.street || "",
      state: shop.state || "",
      city: shop.city || "",
      zip: shop.zip || "",
      socialMedia: {
        facebook: (shop.socialMedia && shop.socialMedia.facebook) || "",
        insta: (shop.socialMedia && shop.socialMedia.insta) || "",
        twitter: (shop.socialMedia && shop.socialMedia.twitter) || "",
        tiktok: (shop.socialMedia && shop.socialMedia.tiktok) || "",
      },

      location: {
        type: "Point",
        coordinates: (shop.location && shop.location.coordinates) || [
          coordinates.longitude,
          coordinates.latitude,
        ],
      },
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const shopId = String(id);
        mutate({
          logo: null,
          ...values,
        });

        if (formik.values.logo) {
          await handleUploadLogo(formik.values.logo, shopId);
        }
      } catch (error) {
        console.error("Error creating shop:", error);
        formik.resetForm();
      }
    },
  });

  const isFormInvalid =
    formik.isValid &&
    formik.values.phoneNumber &&
    formik.values.name &&
    formik.values.city &&
    formik.values.state &&
    formik.values.street &&
    formik.values.zip;

  const handleBlur = async () => {
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${formik.values.street}, ${formik.values.city}, ${formik.values.state}, ${formik.values.zip}.json?access_token=pk.eyJ1Ijoiam9uYmFsYWEiLCJhIjoiY2xydXBvYmU0MGYzNTJsbXJpdW94dTg2aSJ9.-oPQP7bEx5uTemsneAd7YQ`
    );
    const data = await response.json();

    const [longitude, latitude] = data.features[0].center;

    formik.setFieldValue("location", {
      type: "Point",
      coordinates: [longitude, latitude],
    });
    setCoordinates({ longitude: longitude, latitude: latitude });
  };

  useEffect(() => {
    handleBlur();
  }, []);

  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    formik.setFieldValue("logo", file);

    // Convert the dropped file to a data URL for preview
    const reader = new FileReader();
    reader.onload = () => {
      setUploadedImageUrl(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [".jpeg", ".png"],
    },
  });

  return (
    <>
      <div className="flex gap-4 pb-4">
        {shop.isVerifiedStatus !== "DECLINED" && (
          <button
            onClick={handleDecline}
            className="font-small mt-4 w-[8em] rounded-xl bg-[#C64343] py-[10px] text-sm text-white 
                  dark:bg-[#C64343] dark:text-white dark:hover:bg-[#C64343] dark:active:bg-[#C64343]"
          >
            Decline
          </button>
        )}
        {shop.isVerifiedStatus !== "APPROVED" && (
          <button
            onClick={handleApprove}
            className="font-small mt-4 w-[8em] rounded-xl bg-[#71cb90] py-[10px] text-sm text-white 
                  dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Approve
          </button>
        )}
      </div>
      <Card extra={"w-full h-full sm:overflow-auto px-6 mb-4"}>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-col  lg:flex-row">
              {/* Information */}
              <div className="w-full  lg:w-[50%]">
                <h1 className=" pb-2 pt-6 text-xl font-bold text-navy-700 dark:text-white">
                  Shop Details
                </h1>
                <p className="pb-4 text-[13px] text-gray-500">
                  Add shop information (Contact Information, Restaurant
                  Description, Business Hours, Typical Check per Guest, Payment
                  Options, Directions, Parking Info and Social Media Sites)
                </p>
                <div className="w-full">
                  <div className="flex w-full flex-col justify-between gap-4 lg:flex-row">
                    <InputField
                      disabled
                      {...formik.getFieldProps("name")}
                      variant="auth"
                      extra="mb-3 lg:w-[50%]"
                      label="Shop Name*"
                      placeholder="Enter your Shop Name"
                      id="name"
                      type="text"
                      onChange={formik.handleChange}
                    />
                    <InputField
                      disabled
                      {...formik.getFieldProps("email")}
                      variant="auth"
                      extra="mb-3 lg:w-[50%]"
                      label="Email"
                      placeholder="Enter your Email"
                      id="email"
                      type="text"
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row lg:gap-4">
                  <div className="flex w-full flex-col lg:flex-row ">
                    <div className="flex w-full justify-between gap-2">
                      <InputField
                        disabled
                        {...formik.getFieldProps("city")}
                        variant="auth"
                        extra="mb-3 lg:w-[50%]"
                        label="Adress*"
                        placeholder="City"
                        id="city"
                        type="text"
                        onChange={formik.handleChange}
                      />
                      <InputField
                        disabled
                        {...formik.getFieldProps("state")}
                        variant="auth"
                        extra="mb-3 lg:w-[50%]"
                        placeholder="State"
                        id="state"
                        type="text"
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="flex w-full flex-row justify-between gap-2">
                      <InputField
                        disabled
                        {...formik.getFieldProps("street")}
                        variant="auth"
                        extra="mb-3 w-[50%]"
                        placeholder="Street address"
                        id="streetAddress"
                        type="text"
                        onChange={formik.handleChange}
                      />
                      <InputField
                        disabled
                        {...formik.getFieldProps("zip")}
                        variant="auth"
                        extra="mb-3 w-[50%]"
                        placeholder="Zip Code"
                        id="zip"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <div className="flex w-full flex-row justify-between gap-4">
                    <InputField
                      disabled
                      {...formik.getFieldProps("aboutUs")}
                      style={{ height: "5em" }}
                      variant="auth"
                      extra="mb-3 w-[100%] "
                      label="About the Shop"
                      placeholder="A brief informative section the provides users with an overview of the shop"
                      id="aboutUs"
                      type="text"
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="flex w-full flex-col lg:flex-row">
                  <div className="flex flex-col lg:w-[50%]">
                    <div className="w-full">
                      <InputField
                        disabled
                        {...formik.getFieldProps("website")}
                        variant="auth"
                        extra="mb-3 w-[100%] "
                        label="Website"
                        placeholder="The webiste URL of the shop"
                        id="website"
                        type="text"
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="mb-5 w-full ">
                      <FormLabel
                        style={{
                          fontSize: "14px",
                          paddingLeft: "0.3em",
                        }}
                      >
                        Phone Number*
                      </FormLabel>
                      <PhoneInput
                        disabled
                        {...formik.getFieldProps("phoneNumber")}
                        value={formik.values.phoneNumber}
                        disableDropdown
                        inputStyle={{
                          marginTop: "0.5rem",
                          marginBottom: "5px",
                          display: "flex",
                          height: "3rem",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "0.75rem",
                          border: "1px solid #eceaea",
                          backgroundColor: "rgb(243 244 246)",
                          padding: "0.75rem",
                          paddingLeft: "4em",
                          fontSize: "0.875rem",
                          outline: "none",
                          opacity: "0.5",
                        }}
                        country={"us"}
                        placeholder="Enter Last Name"
                        onChange={(value) =>
                          formik.setFieldValue("phoneNumber", value)
                        }
                      />
                    </div>
                  </div>
                  <div className="w-[50%] pl-4">
                    <FormLabel
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      Your Logo
                    </FormLabel>
                    <div
                      {...getRootProps()}
                      className="flex h-[9em] flex-col items-center justify-center border-[1.5px] border-dashed bg-white/0 p-4 text-center"
                    >
                      <input {...getInputProps()} />
                      {!uploadedImageUrl && !shop.logo && (
                        <>
                          <LuUpload
                            style={{ fontSize: "2em", color: "#979797" }}
                          />
                          <p className="text-[16px] font-medium text-[#979797]">
                            Upload Files
                          </p>
                          <p className="text-[12px] text-[#979797]">
                            PNG and JPG are allowed
                          </p>
                        </>
                      )}
                      {(uploadedImageUrl || (shop.logo && shop.logo.path)) && (
                        <img
                          src={
                            uploadedImageUrl || (shop.logo && shop.logo.path)
                          }
                          alt="Uploaded Logo"
                          className="h-[10em] max-w-full"
                          style={{ width: "60%", height: "100%" }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row lg:gap-4">
                  <div className="flex w-full flex-col lg:flex-row ">
                    <div className="flex w-full justify-between gap-2">
                      <InputField
                        disabled
                        {...formik.getFieldProps("socialMedia.insta")}
                        variant="auth"
                        extra="mb-3 w-[50%]"
                        label="Social Media"
                        placeholder="Instagram URL"
                        id="insta"
                        type="text"
                        onChange={formik.handleChange}
                      />
                      <InputField
                        disabled
                        {...formik.getFieldProps("socialMedia.facebook")}
                        variant="auth"
                        extra="mb-3 w-[50%]"
                        placeholder="Facebook URL"
                        id="facebook"
                        type="text"
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="flex w-full flex-row justify-between gap-2">
                      <InputField
                        disabled
                        {...formik.getFieldProps("socialMedia.twitter")}
                        variant="auth"
                        extra="mb-3 w-[50%]"
                        placeholder="Twitter URL"
                        id="twitter"
                        type="text"
                        onChange={formik.handleChange}
                      />
                      <InputField
                        disabled
                        {...formik.getFieldProps("socialMedia.tiktok")}
                        variant="auth"
                        extra="mb-3 w-[50%]"
                        placeholder="Tiktok URL"
                        id="tiktok"
                        type="text"
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* Map */}
              <div
                style={{ borderRadius: "10px" }}
                className="mb-[2em] mt-[5em] w-[50%] pl-10 "
              >
                <GoogleMap coordinates={coordinates} />
              </div>
            </div>

            <div className="flex justify-end pb-6">
              <button
                className={`mt-2 w-[5em] rounded-xl py-[12px] text-base font-medium text-white transition duration-200 
          ${
            !isFormInvalid
              ? "cursor-not-allowed bg-gray-400"
              : "bg-[#71cb90] hover:bg-[#71cb90] active:bg-[#71cb90]"
          }
          dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
                disabled={!isFormInvalid}
              >
                {isLoading ? <Spinner /> : "Save"}
              </button>
            </div>
          </form>
        </FormikProvider>
      </Card>
    </>
  );
};
