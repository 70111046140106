import axios, { AxiosRequestHeaders } from "axios";
import { refreshToken } from "api/auth/auth"; // Import your refresh token function
import { useNavigate } from "react-router-dom";

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((request) => {
  if (!request.headers) {
    request.headers = {} as AxiosRequestHeaders;
  }
  request.headers.Accept = "application/json";

  // Attach token to request if available
  const jwtToken = localStorage.getItem("authToken");
  if (jwtToken) {
    request.headers.Authorization = `Bearer ${jwtToken}`;
  }

  // Send content-type with POST/PUT requests
  if (request.method === "POST" || request.method === "PUT") {
    request.headers["Content-Type"] = "application/json";
  }

  return request;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
       let refresh_token =  localStorage.getItem("access_token_expiration")
        const response = await refreshToken(refresh_token); // Call the function to refresh the token
        const newToken = response.idToken;
        localStorage.setItem("authToken", newToken);
        axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        const navigate = useNavigate();
        localStorage.removeItem("authToken");
        localStorage.removeItem("access_token_expiration");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("useObject");
        navigate("/auth/login");
        window.location.reload();
      }
    }
    return Promise.reject(error);
  }
);
