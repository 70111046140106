// Admin Imports
import MainDashboard from "views/admin/default";

// Icon Imports
import { MdAddBusiness, MdHome } from "react-icons/md";
import { FaShippingFast } from "react-icons/fa";
import { PiFlowerLotusBold } from "react-icons/pi";
import { FaBookOpen } from "react-icons/fa";
import { IoSettingsSharp } from "react-icons/io5";
import { TbFileInvoice } from "react-icons/tb";
import { Role } from "interfaces/auth/auth";
import ShopView from "views/admin/shop";
import ProductView from "views/admin/product/products";
import { BiSolidReport } from "react-icons/bi";
import OrdersView from "views/admin/orders";
import { IoSettings } from "react-icons/io5";
import ConfigsView from "views/admin/configs/configs";
import ReportsView from "views/admin/reports/reports";
import InvoicesView from "views/admin/invoices";
import ShippingView from "views/admin/shipping";
import SettingsView from "views/admin/globals";

const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
    roles: [Role.ADMIN, Role.PLACE_OWNER],
  },
  {
    name: "Flower Shops",
    layout: "/admin",
    path: "shop",
    icon: <MdAddBusiness className="h-6 w-6" />,
    component: <ShopView />,
    roles: [Role.ADMIN, Role.PLACE_OWNER],
  },
  {
    name: "Shipping",
    layout: "/admin",
    path: "shipping",
    icon: <FaShippingFast className="h-6 w-6" />,
    component: <ShippingView />,
    roles: [Role.PLACE_OWNER],
  },
  {
    name: "Products",
    layout: "/admin",
    path: "products",
    icon: <PiFlowerLotusBold className="h-6 w-6" />,
    component: <ProductView />,
    roles: [Role.ADMIN, Role.PLACE_OWNER],
  },
  {
    name: "Orders",
    layout: "/admin",
    path: "orders",
    icon: <FaBookOpen className="h-6 w-6" />,
    component: <OrdersView />,
    roles: [Role.ADMIN, Role.PLACE_OWNER],
  },
  {
    name: "Reports",
    layout: "/admin",
    path: "reports",
    icon: <BiSolidReport className="h-6 w-6" />,
    component: <ReportsView />,
    roles: [Role.ADMIN, Role.PLACE_OWNER],
  },
  {
    name: "Configs",
    layout: "/admin",
    path: "configs",
    icon: <IoSettingsSharp className="h-6 w-6" />,
    component: <ConfigsView />,
    roles: [Role.ADMIN],
  },
  {
    name: "Globals",
    layout: "/admin",
    path: "globals",
    icon: <IoSettings className="h-6 w-6" />,
    component: <SettingsView />,
    roles: [Role.ADMIN],
  },
  {
    name: "Invoices",
    layout: "/admin",
    path: "invoices",
    icon: <TbFileInvoice className="h-6 w-6" />,
    component: <InvoicesView />,
    roles: [Role.ADMIN],
  },
];
export default routes;
