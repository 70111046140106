import { AuthContext } from "contexts/AuthContext";
import { Role } from "interfaces/auth/auth";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProductTable from "./components/ProductTable";
import Loader from "components/loader/Loader";
import { useGetFilteredProducts } from "hooks/products/useGetFilteredProducts";
import { FilteredProductsPayload, SortType } from "interfaces/product/product";
import AdminProductTable from "./components/AdminProductTable";
import { useGetOwnerProducts } from "hooks/products/useGetOwnerProducts";
import { useGetAllShops } from "hooks/shop/useGetAllShop";
import { useGetShopOwners } from "hooks/shop/useGetShopOwner";

const ProductView = () => {
  const [user] = useContext(AuthContext);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [sortField, setSortField] = useState<string>("name");
  const [sortType, setSortType] = useState<SortType>(SortType.ASC);


  const { data: flowerShops } = useGetAllShops();
  const { data: ownerFlowersShops } = useGetShopOwners();

  const filters: FilteredProductsPayload = {
    limit: itemsPerPage,
    page: currentPage,
    pageCount: true,
    filter: selectedId
      ? [
          {
            field: "flowerShopId",
            operator: "Equal",
            value: selectedId,
          },
        ]
      : [],
   sort: [
      {
        field: sortField,
        sortType: sortType,
      },
    ],
  };

  const {
    data: admin,
    isLoading: adminLoading,
    refetch: refetchAdmin,
  } = useGetFilteredProducts(filters);

  const {
    data: placeOwner,
    isLoading: placeOwnerLoading,
    refetch: refetchOwner,
  } = useGetOwnerProducts(filters);

  const addNew = () => {
    navigate(`/admin/products/add-product`);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleShopChange = (id: string | null) => {
    setSelectedId(id);
    setCurrentPage(1);
  };
  const handleSortChange = (field: string, type: SortType) => {
    setSortField(field);
    setSortType(type);
  };
  useEffect(() => {
    if (user.roles.some((role) => role === Role.ADMIN)) {
      refetchAdmin();
    } else {
      refetchOwner();
    }
  }, [currentPage, selectedId, sortField, sortType]);

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="w-ful l mt-3 flex h-fit flex-col gap-5">
        <div className="col-span-4 lg:!mb-0">
          <>
            {user.roles.some((role) => role === Role.ADMIN) && (
              <>
                {admin?.results && admin?.results?.length > 0 ? (
                  <>
                    <AdminProductTable
                      itemsPerPage={itemsPerPage}
                      currentPage={currentPage}
                      sortField={sortField}
                      sortType={sortType}
                      handlePageChange={handlePageChange}
                      handleSortChange={handleSortChange}
                      tableData={admin}
                      flowerShops={flowerShops || []}
                      selectedId={selectedId}
                      handleShopChange={handleShopChange}
                    />
                    <div className="flex justify-end"></div>
                  </>
                ) : adminLoading ? (
                  <Loader />
                ) : (
                  <div className="flex flex-col items-center justify-center pt-[15em]">
                    <p>There are no products, click the button to add.</p>
                  </div>
                )}
              </>
            )}
            {user.roles.some((role) => role === Role.PLACE_OWNER) && (
              <>
                {placeOwner?.results && placeOwner?.results.length > 0 ? (
                  <>
                    <ProductTable
                      itemsPerPage={itemsPerPage}
                      currentPage={currentPage}
                      sortField={sortField}
                      sortType={sortType}
                      flowerShops={ownerFlowersShops || []}
                      handlePageChange={handlePageChange}
                      handleSortChange={handleSortChange}
                      selectedId={selectedId}
                      handleShopChange={handleShopChange}
                      tableData={placeOwner}
                    />
                    <div className="flex justify-end">
                      <button
                        onClick={addNew}
                        className="font-small mt-4 w-[6em] rounded-xl bg-[#71cb90] py-[8px] text-sm text-white 
            dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                      >
                        Add
                      </button>
                    </div>
                  </>
                ) : placeOwnerLoading ? (
                  <Loader />
                ) : (
                  <div className="flex flex-col items-center justify-center pt-[15em]">
                    <p>There are no restaurants, click the button to add.</p>
                    <button
                      onClick={addNew}
                      className="mt-4 w-[6em] rounded-xl bg-[#71cb90] py-[8px] text-base font-medium text-white 
          dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    >
                      Add
                    </button>
                  </div>
                )}
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default ProductView;
