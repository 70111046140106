import { useMutation } from "@tanstack/react-query";
import { ForgotPasswordPayload } from "../../interfaces/auth/auth";
import { forgotPassword } from "../../api/auth/auth";
import { useToast } from "@chakra-ui/react";

export const useForgotPassword = () => {
  const toast = useToast();
  return useMutation(
    (payload: ForgotPasswordPayload) => forgotPassword(payload),
    {
      onSuccess: () => {
        toast({
          title: "Link has been sent",
          description:
            "The link to reset your password has been sent, please continue to your email.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: "The email was not sent! ",
          status: "error",
          duration: 1000,
          isClosable: true,
        });
      },
    }
  );
};
