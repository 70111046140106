import { useMutation, useQueryClient } from "@tanstack/react-query";
import { uploadProductPhotos } from "api/product/product";

export const useUploadProductPhotos = () => {
  const queryClient = useQueryClient();

  return useMutation((formData: FormData) => uploadProductPhotos(formData), {
    onSuccess: (_, variables, context: { refetch: () => void }) => {
      context?.refetch();
      queryClient.invalidateQueries(["products"]);
    },
    onError: () => {},
  });
};
