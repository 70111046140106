import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteCommision } from "api/configs/configs";

export const useDeleteComission = () => {
  const queryClient = useQueryClient();

  return useMutation((id: string) => deleteCommision(id), {
    onSuccess: (_, variables, context: { refetch: () => void }) => {
      context?.refetch();
      queryClient.invalidateQueries(["comission"]);
    },
  });
};
