import { Spinner } from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import { TokenContext } from "contexts/AuthContext";
import { FormikProvider, useFormik } from "formik";
import { useToken } from "hooks/api/useToken";
import { useUpdatePassword } from "hooks/api/useUpdatePassword";
import { PasswordUpdatePayload } from "interfaces/auth/auth";
import { useContext } from "react";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  previousPassword: Yup.string()
    .required("Current Password is required")
    .notOneOf(
      [Yup.ref("proposedPassword")], // changed 'password' to 'proposedPassword'
      "Current Password must be different from the new password"
    )
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),
  proposedPassword: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),
});

const EditPasswordForm: React.FC = () => {
  const { mutate, isLoading } = useUpdatePassword();
  const { token } = useContext(TokenContext);
  const { refetch } = useToken();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      previousPassword: "",
      proposedPassword: "",
      accessToken: token.AuthenticationResult.AccessToken,
    },
    validationSchema: validationSchema,
    onSubmit: (values: PasswordUpdatePayload) => {
      mutate(
        {
          payload: { ...values },
        },
        {
          onSuccess: () => {
            refetch();
            formik.resetForm();
          },
          onError: () => {
            formik.resetForm();
          },
        }
      );
    },
  });

  const isFormInvalid = !formik.isValid;

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <InputField
            {...formik.getFieldProps("previousPassword")}
            variant="auth"
            extra="mb-3"
            label="Current Password*"
            placeholder="Enter your Current Password"
            id="previousPassword"
            type="password"
            onChange={formik.handleChange}
          />
          <InputField
            {...formik.getFieldProps("proposedPassword")}
            variant="auth"
            extra="mb-3"
            label="New Password*"
            placeholder="New Password"
            id="proposedPassword"
            type="password"
            onChange={formik.handleChange}
          />
          <button
            type="submit"
            className={`mt-2 w-full rounded-xl py-[12px] text-base font-medium text-white transition duration-200 
        ${
          isFormInvalid
            ? "cursor-not-allowed bg-gray-400"
            : "bg-[#71cb90] hover:bg-[#71cb90] active:bg-[#71cb90]"
        }
        `}
            disabled={isFormInvalid}
          >
            {isLoading ? <Spinner /> : "Update Password"}
          </button>
        </form>
      </FormikProvider>
    </>
  );
};

export default EditPasswordForm;
