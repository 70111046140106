import { axiosInstance } from "api/config";
import { BaseUrl } from "enums/BaseUrl";

export interface QRandCodeResponse {
  giftMessage: string;
  mediaItems: MediaItems[];
}

interface MediaItems {
  _id: string;
  contentType: string;
  fullPath: string;
  id: string;
  path: string;
  type: string;
}

export const getQR = async (orderId: string) => {
  const { data } = await axiosInstance.get<any>(
    `${BaseUrl.DEVELOPMENT}/orders/orderByIdExists/${orderId}`
  );

  return data;
};

export const getQRandCode = async (orderId: string, orderCode?: string) => {
  const { data } = await axiosInstance.get<QRandCodeResponse>(
    `${BaseUrl.DEVELOPMENT}/orders/getOrderByIdAndCode/${orderId}/${orderCode}`
  );

  return data;
};
