import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import {
  AddConfigs,
  Comission,
  CommisionPayload,
  Configs,
} from "api/configs/configs";
import Card from "components/card";
import { useAddConfig } from "hooks/configs/useAddConfig";
import { useGetConfigs } from "hooks/configs/useGetConfigs";
import { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import AddComission from "./components/AddComission";
import { useGetComissions } from "hooks/configs/useGetCommision";
import { useAddComission } from "hooks/configs/useAddComission";
import { useDeleteComission } from "hooks/configs/useDeleteComission";

const defaultComissionDetail: Comission = {
  from: null,
  to: null,
  fee: null,
  _id: "",
};

const ConfigsView = () => {
  const { data } = useGetConfigs();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [newValue, setNewValue] = useState("");
  const { mutate } = useAddConfig();
  const { mutate: addCommision } = useAddComission();
  const { mutate: deleteCommision } = useDeleteComission();

  const [comissionDetails, setComissionDetails] = useState<Comission[]>([
    defaultComissionDetail,
  ]);

  const { data: comissionData } = useGetComissions();

  useEffect(() => {
    if (comissionData && comissionData.length > 0) {
      setComissionDetails(comissionData);
    } else {
      setComissionDetails([defaultComissionDetail]);
    }
  }, [comissionData]);

  const formatKey = (key: string) => {
    const words = key.split("_");
    const formattedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return formattedWords.join(" ");
  };

  const handleEditClick = (config: Configs) => {
    setSelectedConfig(config);
    setNewValue(config?.value?.toString());
    onOpen();
  };

  const addComissionDetail = () => {
    setComissionDetails([...comissionDetails, defaultComissionDetail]);
  };

  const removeComissionDetail = (index: number) => {
    const detailToRemove = comissionDetails[index];
    if (detailToRemove._id) {
      deleteCommision(detailToRemove._id, {
        onSuccess: () => {
          const updatedComissionDetails = comissionDetails.filter(
            (_, i) => i !== index
          );
          setComissionDetails(updatedComissionDetails);
        },
      });
    } else {
      const updatedComissionDetails = comissionDetails.filter(
        (_, i) => i !== index
      );
      setComissionDetails(updatedComissionDetails);
    }
  };

  const handleComissionChange = (
    index: number,
    field: string,
    value: number
  ) => {
    const newComissionDetails = [...comissionDetails];
    newComissionDetails[index] = {
      ...newComissionDetails[index],
      [field]: value,
    };
    setComissionDetails(newComissionDetails);
  };

  const handleSave = () => {
    if (selectedConfig) {
      const payload: AddConfigs = {
        key: selectedConfig.key,
        value: parseFloat(newValue),
      };
      mutate(payload, {
        onSuccess: () => {
          onClose();
        },
      });
    }
  };

  const handleAddComission = () => {
    addCommision(
      comissionDetails.map((detail) => ({
        ...detail,
      }))
    );
  };

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="w-ful l mt-3 flex h-fit flex-col gap-5">
        <Card extra="p-6">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {data
              ?.filter((configs) => configs.key !== "MESSAGE")
              .map((configs) => (
                <div
                  key={configs._id}
                  className="relative flex flex-col gap-2 rounded-lg bg-gray-200 p-4"
                >
                  <div className="flex items-center gap-2">
                    <h1 className="font-semibold">{formatKey(configs.key)}:</h1>
                    <p>{configs.value}</p>
                  </div>
                  <div
                    onClick={() => handleEditClick(configs)}
                    className="absolute right-2 top-2 cursor-pointer"
                  >
                    <MdEdit className="cursor-pointer text-gray-500 hover:text-gray-700" />
                  </div>
                </div>
              ))}
          </div>
        </Card>
        <Card extra="p-6">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            <AddComission
              comissionDetails={comissionDetails}
              onComissionChange={handleComissionChange}
              onAddComissionDetail={addComissionDetail}
              onRemoveComissionDetail={removeComissionDetail}
            />
          </div>
          <div className="mt-4 flex justify-end">
            <button
              className={`font-small } w-[12em] rounded-xl bg-[#71cb90] p-4 py-[8px] text-sm 
              text-white`}
              onClick={handleAddComission}
            >
              Save
            </button>
          </div>
        </Card>
      </div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <h1 className="font-semibold">
              {selectedConfig && formatKey(selectedConfig.key)}
            </h1>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <input
              value={newValue}
              onChange={(e) => setNewValue(e.target.value)}
              placeholder="Enter your Config Value"
              id="configValue"
              type="text"
              className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border p-3 text-sm outline-none"
            />
          </ModalBody>
          <ModalFooter>
            <Button
              backgroundColor="#71cb90"
              color="white"
              mr={3}
              onClick={handleSave}
            >
              Save
            </Button>
            <Button colorScheme="red" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ConfigsView;
