import React from "react";
import BarChart from "components/charts/BarChart";
import { barChartOptionsDailyTraffic } from "variables/charts";
import Card from "components/card";

interface FlowerTypeDetails {
  _id: string;
  name: string;
}

interface Sells {
  count: number;
  flowerTypeDetails: FlowerTypeDetails[];
}

interface Props {
  mostSold: Sells[];
}

const DailyTraffic: React.FC<Props> = ({ mostSold }) => {
  // Transform the fetched data to the required format
  const barChartData = mostSold
    ? [
        {
          name: "Sells",
          data: mostSold.map((item) => item.count), // Only the count here
        },
      ]
    : [];

  const categories = mostSold
    ? mostSold.map((item) => {
        const flowerName = item.flowerTypeDetails[0]?.name;
        return flowerName
          ? flowerName.length > 10
            ? flowerName.slice(0, 10) + "..."
            : flowerName
          : "Unknown";
      })
    : [];

  // Update chart options to include dynamic categories
  const updatedBarChartOptions = {
    ...barChartOptionsDailyTraffic,
    xaxis: {
      ...barChartOptionsDailyTraffic.xaxis,
      categories: categories,
    },
  };

  return (
    <Card extra="pb-7 p-[20px]">
      <div className="flex flex-row justify-between">
        <div className="ml-1 pt-2">
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">
            Most Sold Products
          </h4>
        </div>
      </div>
      <div className="h-[300px] w-full pb-0 pt-10">
        {mostSold && mostSold.length > 0 ? (
          <BarChart
            chartData={barChartData}
            chartOptions={updatedBarChartOptions}
          />
        ) : (
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">
            No sold products..
          </h4>
        )}
      </div>
    </Card>
  );
};

export default DailyTraffic;
