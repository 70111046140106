import { Box, Button, Image } from "@chakra-ui/react";
import Card from "components/card";
import { useGetQRandCode } from "hooks/qr-code/useGetQR";
import { useNavigate, useParams } from "react-router-dom";

const OrderMessageView = () => {
  const { orderId, code } = useParams();
  const navigate = useNavigate();
  const { data } = useGetQRandCode(orderId, code);

  const dashboard = () => {
    navigate(`/admin/default`);
  };

  console.log("data", data);

  return (
    <div className="flex w-full flex-col items-center justify-center">
      <Card extra="m-6 w-[50%] p-6">
        <h1 className="text-[22px] font-bold">Gift Message:</h1>
        <p>{data?.giftMessage}</p>
      </Card>
      <Card extra="w-[50%] mb-4 p-6">
        <Box alignItems="center" display="flex" flexDirection="column" gap={4}>
          {data?.mediaItems?.map((item) => (
            <Box key={item.id} display="flex" justifyContent="center">
              {item.contentType.startsWith("image/") && (
                <Image w={100} h={100} src={item.path} />
              )}
              {item.contentType.startsWith("audio/") && (
                <audio controls>
                  <source src={item.path} type={item.contentType} />
                  Your browser does not support the audio element.
                </audio>
              )}
              {item.contentType.startsWith("video/") && (
                <video width="100" height="100" controls>
                  <source src={item.path} type={item.contentType} />
                  Your browser does not support the video element.
                </video>
              )}
            </Box>
          ))}
        </Box>
      </Card>
      <Button onClick={dashboard} type="submit" colorScheme="blue" mr={3}>
        Go Back To Dashboard
      </Button>
    </div>
  );
};

export default OrderMessageView;
