export interface AddFlowerPayload {
  _id?: string;
  name: string;
  desc: string;
  flowerShopId: string;
  currentPrice: number;
  discount?: number;
  flowerTypeId: string;
  createdBy?: string;
  isAddOn: boolean;
  sizesWithPrices?: {
    size: string;
    price: number;
  }[];
}

export interface AddFloweObject {
  id: string;
  payload: AddFlowerPayload;
}
export interface FlowerType {
  _id: string;
  name: string;
}

export interface ProductResponse {
  name: string;
  desc: string;
  file: any[];
  flowerShopId: string;
  createdBy: string;
  flowerTypeId: string;
  currentPrice: number;
  discount: number;
  isAddOn: boolean;
  pricingOptionId: string | null;
  selectedOptions: SelectedOptions[];
  sizesWithPrices: SizeWithPrice[];
  _id: string;
}

export interface DashboardProductResponse {
  name: string;
  desc: string;
  file: any[];
  flowerShopId: string;
  createdBy: string;
  flowerTypeId: string;
  currentPrice: number;
  originalPrice: number;
  discount: number;
  isAddOn: boolean;
  pricingOptionId: string | null;
  selectedOptions: SelectedOptions[];
  originalSizesWithPrices: OriginalSizeWithPrice[];
  calculatedSizesWithPrices: SizeWithPrice[];
  _id: string;
}

interface SelectedOptions {
  desc: string;
  name: string;
  _id: string;
}

interface SizeWithPrice {
  size: string;
  price: number;
  _id: string;
}

interface OriginalSizeWithPrice {
  size: string;
  originalPrice: number;
  _id: string;
}

export interface FilteredProductsPayload {
  limit: number;
  page: number;
  pageCount: boolean;
  filter: {
    field: string;
    operator: string;
    value: any;
  }[];
  sort: {
    field: string;
    sortType: SortType;
  }[];
}

export enum SortType {
  DESC = "DESC",
  ASC = "ASC",
}
