import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import avatar from "assets/img/avatars/avata100.png";
import banner from "assets/img/profile/banner.png";
import Card from "components/card";
import { AuthContext } from "contexts/AuthContext";
import { useContext } from "react";
import EditProfileForm from "./EditProfileForm";
import EditPasswordForm from "./EditPasswordForm";

const Banner = () => {
  const [user] = useContext(AuthContext);
  return (
    <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
      <div
        className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-white dark:!border-navy-700">
          <img className="h-full w-full rounded-full" src={avatar} alt="" />
        </div>
      </div>
      <div className="mt-16 flex flex-col items-center">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          {user.first_name} {user.last_name}
        </h4>
        <p className="text-base font-normal text-gray-600">{user.username}</p>
      </div>
      <div className="mb-3 mt-6 flex gap-4 md:!gap-14">
        <Tabs variant="enclosed">
          <TabList>
            <Tab color={`black`}>Profile Inforamtion</Tab>
            <Tab color={`black`}>Change Password</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <EditProfileForm />
            </TabPanel>
            <TabPanel>
              <EditPasswordForm />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </Card>
  );
};

export default Banner;
