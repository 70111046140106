import {
  AuthContext,
  TokenContext,
  defaultAuthResponse,
  defaultTokenResponse,
} from "contexts/AuthContext";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { AuthResponse, LoginResponse } from "interfaces/auth/auth";
import { useState, useEffect } from "react";
import { Router } from "router/Router";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FC = () => {
  const storedUser = localStorage.getItem("user");
  const initialUser: LoginResponse = storedUser
    ? JSON.parse(storedUser)
    : defaultAuthResponse;

  const storedToken = localStorage.getItem("authToken");
  const storedTokenAccess = localStorage.getItem("tokenAccess");
  const initialToken: AuthResponse = storedToken
    ? {
        AuthenticationResult: {
          AccessToken: storedTokenAccess,
          IdToken: storedToken,
          RefreshToken: "",
        },
      }
    : defaultTokenResponse;

  const [user, setUser] = useState(initialUser);
  const [token, setToken] = useState<AuthResponse>(initialToken);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  useEffect(() => {
    if (token.AuthenticationResult.IdToken) {
      localStorage.setItem("authToken", token.AuthenticationResult.IdToken);
      localStorage.setItem(
        "tokenAccess",
        token.AuthenticationResult.AccessToken
      );
    } else {
      localStorage.removeItem("authToken");
      localStorage.removeItem("tokenAccess");
    }
  }, [token]);

  return (
    <ChakraProvider>
      <QueryClientProvider client={queryClient}>
        <TokenContext.Provider value={{ token, setToken }}>
          <AuthContext.Provider value={[user, setUser]}>
            <Router />
          </AuthContext.Provider>
        </TokenContext.Provider>
      </QueryClientProvider>
    </ChakraProvider>
  );
};

export default App;
