import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AddCommision, addCommision } from "api/configs/configs";

export const useAddComission = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation((payload: AddCommision[]) => addCommision(payload), {
    onSuccess: (_, variables, context: { refetch: () => void }) => {
      toast({
        title: "Comissions were updated successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      context?.refetch();
      queryClient.invalidateQueries(["comission"]);
    },
    onError: () => {
      toast({
        title: "Comissions failed to be updated!",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    },
  });
};
