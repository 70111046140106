import { useNavigate } from "react-router-dom";
import Loader from "components/loader/Loader";
import { useContext, useState } from "react";
import { AuthContext } from "contexts/AuthContext";
import { Role } from "interfaces/auth/auth";
import { useGetShopOwners } from "hooks/shop/useGetShopOwner";
import ShopTable from "./components/ShopTable";
import { useGetAllShops } from "hooks/shop/useGetAllShop";
import AdminShopTable from "./components/AdminShopTable";
import { SortType } from "interfaces/product/product";

const ShopView = () => {
  const [user] = useContext(AuthContext);
  const { data, isLoading } = useGetAllShops();
  const { data: placeOwner, isLoading: placeOwnerLoading } = useGetShopOwners();
  const [sortField, setSortField] = useState<string>("name");
  const [sortType, setSortType] = useState<SortType>(SortType.ASC);
  const navigate = useNavigate();

  const addNew = () => {
    navigate(`/admin/shop/add-shop`);
  };
  const handleSortChange = (field: string, type: SortType) => {
    setSortField(field);
    setSortType(type);
  };
  return (
    <div className="flex w-full flex-col gap-5">
      <div className="w-ful l mt-3 flex h-fit flex-col gap-5">
        <div className="col-span-4 lg:!mb-0">
          <>
            {user.roles.some((role) => role === Role.ADMIN) && (
              <>
                {data && data.length > 0 ? (
                  <AdminShopTable tableData={data} />
                ) : isLoading ? (
                  <Loader />
                ) : (
                  <div className="flex flex-col items-center justify-center pt-[15em]">
                    <p>There are no restaurants :(</p>
                  </div>
                )}
              </>
            )}
            {user.roles.some((role) => role === Role.PLACE_OWNER) && (
              <>
                {placeOwner && placeOwner.length > 0 ? (
                  <>
                    <ShopTable tableData={placeOwner}
                                          sortField={sortField}
                                          sortType={sortType}
                                          handleSortChange={handleSortChange}
                                          />
                    <div className="flex justify-end">
                      <button
                        onClick={addNew}
                        className="font-small mt-4 w-[6em] rounded-xl bg-[#71cb90] py-[8px] text-sm text-white 
            dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                      >
                        Add
                      </button>
                    </div>
                  </>
                ) : placeOwnerLoading ? (
                  <Loader />
                ) : (
                  <div className="flex flex-col items-center justify-center pt-[15em]">
                    <p>There are no restaurants, click the button to add.</p>
                    <button
                      onClick={addNew}
                      className="mt-4 w-[6em] rounded-xl bg-[#71cb90] py-[8px] text-base font-medium text-white 
          dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    >
                      Add
                    </button>
                  </div>
                )}
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default ShopView;
